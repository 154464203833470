const beeful = [
    {
        "variations": [{
            "id": 100,
            "color": "Black",
            "size": "S",
            "sku": "UT-5000-Blk-S"
        },
        {
            "id": 101,
            "color": "Black",
            "size": "M",
            "sku": "UT-5000-Blk-M"
        },
        {
            "id": 102,
            "color": "Black",
            "size": "L",
            "sku": "UT-5000-Blk-L"
        },
        {
            "id": 103,
            "color": "Black",
            "size": "XL",
            "sku": "UT-5000-Blk-XL"
        },
        {
            "id": 104,
            "color": "Black",
            "size": "2XL",
            "sku": "UT-5000-Blk-2XL"
        },
        {
            "id": 105,
            "color": "Black",
            "size": "3XL",
            "sku": "UT-5000-Blk-3XL"
        },
        {
            "id": 207,
            "color": "Sand",
            "size": "S",
            "sku": "UT-5000-Sad-S"
        },
        {
            "id": 208,
            "color": "Sand",
            "size": "M",
            "sku": "UT-5000-Sad-M"
        },
        {
            "id": 209,
            "color": "Sand",
            "size": "L",
            "sku": "UT-5000-Sad-L"
        },
        {
            "id": 210,
            "color": "Sand",
            "size": "XL",
            "sku": "UT-5000-Sad-XL"
        },
        {
            "id": 211,
            "color": "Sand",
            "size": "2XL",
            "sku": "UT-5000-Sad-2XL"
        },
        {
            "id": 212,
            "color": "Sand",
            "size": "3XL",
            "sku": "UT-5000-Sad-3XL"
        },
        {
            "id": 154,
            "color": "White",
            "size": "S",
            "sku": "UT-5000-Whe-S"
        },
        {
            "id": 155,
            "color": "White",
            "size": "M",
            "sku": "UT-5000-Whe-M"
        },
        {
            "id": 156,
            "color": "White",
            "size": "L",
            "sku": "UT-5000-Whe-L"
        },
        {
            "id": 157,
            "color": "White",
            "size": "XL",
            "sku": "UT-5000-Whe-XL"
        },
        {
            "id": 158,
            "color": "White",
            "size": "2XL",
            "sku": "UT-5000-Whe-2XL"
        },
        {
            "id": 159,
            "color": "White",
            "size": "3XL",
            "sku": "UT-5000-Whe-3XL"
        },
        {
            "id": 160,
            "color": "SPORT GREY",
            "size": "S",
            "sku": "UT-5000-SPY-S"
        },
        {
            "id": 161,
            "color": "SPORT GREY",
            "size": "M",
            "sku": "UT-5000-SPY-M"
        },
        {
            "id": 162,
            "color": "SPORT GREY",
            "size": "L",
            "sku": "UT-5000-SPY-L"
        },
        {
            "id": 163,
            "color": "SPORT GREY",
            "size": "XL",
            "sku": "UT-5000-SPY-XL"
        },
        {
            "id": 164,
            "color": "SPORT GREY",
            "size": "2XL",
            "sku": "UT-5000-SPY-2XL"
        },
        {
            "id": 165,
            "color": "SPORT GREY",
            "size": "3XL",
            "sku": "UT-5000-SPY-3XL"
        },
        {
            "id": 166,
            "color": "DARK HEATHER",
            "size": "S",
            "sku": "UT-5000-DAR-S"
        },
        {
            "id": 167,
            "color": "DARK HEATHER",
            "size": "M",
            "sku": "UT-5000-DAR-M"
        },
        {
            "id": 168,
            "color": "DARK HEATHER",
            "size": "L",
            "sku": "UT-5000-DAR-L"
        },
        {
            "id": 169,
            "color": "DARK HEATHER",
            "size": "XL",
            "sku": "UT-5000-DAR-XL"
        },
        {
            "id": 170,
            "color": "DARK HEATHER",
            "size": "2XL",
            "sku": "UT-5000-DAR-2XL"
        },
        {
            "id": 171,
            "color": "DARK HEATHER",
            "size": "3XL",
            "sku": "UT-5000-DAR-3XL"
        },
        {
            "id": 2984,
            "color": "FOREST GREEN",
            "size": "S",
            "sku": "UT-5000-FON-S"
        },
        {
            "id": 2845,
            "color": "FOREST GREEN",
            "size": "M",
            "sku": "UT-5000-FON-M"
        },
        {
            "id": 2991,
            "color": "FOREST GREEN",
            "size": "L",
            "sku": "UT-5000-FON-L"
        },
        {
            "id": 2998,
            "color": "FOREST GREEN",
            "size": "XL",
            "sku": "UT-5000-FON-XL"
        },
        {
            "id": 2831,
            "color": "FOREST GREEN",
            "size": "2XL",
            "sku": "UT-5000-FON-2XL"
        },
        {
            "id": 2835,
            "color": "FOREST GREEN",
            "size": "3XL",
            "sku": "UT-5000-FON-3XL"
        },
        {
            "id": 112,
            "color": "LIGHT BLUE",
            "size": "S",
            "sku": "UT-5000-LIE-S"
        },
        {
            "id": 113,
            "color": "LIGHT BLUE",
            "size": "M",
            "sku": "UT-5000-LIE-M"
        },
        {
            "id": 114,
            "color": "LIGHT BLUE",
            "size": "L",
            "sku": "UT-5000-LIE-L"
        },
        {
            "id": 115,
            "color": "LIGHT BLUE",
            "size": "XL",
            "sku": "UT-5000-LIE-XL"
        },
        {
            "id": 116,
            "color": "LIGHT BLUE",
            "size": "2XL",
            "sku": "UT-5000-LIE-2XL"
        },
        {
            "id": 117,
            "color": "LIGHT BLUE",
            "size": "3XL",
            "sku": "UT-5000-LIE-3XL"
        },
        {
            "id": 195,
            "color": "LIGHT PINK",
            "size": "S",
            "sku": "UT-5000-LIK-S"
        },
        {
            "id": 196,
            "color": "LIGHT PINK",
            "size": "M",
            "sku": "UT-5000-LIK-M"
        },
        {
            "id": 197,
            "color": "LIGHT PINK",
            "size": "L",
            "sku": "UT-5000-LIK-L"
        },
        {
            "id": 198,
            "color": "LIGHT PINK",
            "size": "XL",
            "sku": "UT-5000-LIK-XL"
        },
        {
            "id": 199,
            "color": "LIGHT PINK",
            "size": "2XL",
            "sku": "UT-5000-LIK-2XL"
        },
        {
            "id": 200,
            "color": "LIGHT PINK",
            "size": "3XL",
            "sku": "UT-5000-LIK-3XL"
        },
        {
            "id": 3017,
            "color": "ROYAL",
            "size": "S",
            "sku": "UT-5000-ROL-S"
        },
        {
            "id": 3018,
            "color": "ROYAL",
            "size": "M",
            "sku": "UT-5000-ROL-M"
        },
        {
            "id": 3019,
            "color": "ROYAL",
            "size": "L",
            "sku": "UT-5000-ROL-L"
        },
        {
            "id": 3020,
            "color": "ROYAL",
            "size": "XL",
            "sku": "UT-5000-ROL-XL"
        },
        {
            "id": 3021,
            "color": "ROYAL",
            "size": "2XL",
            "sku": "UT-5000-ROL-2XL"
        },
        {
            "id": 3022,
            "color": "ROYAL",
            "size": "3XL",
            "sku": "UT-5000-ROL-3XL"
        },
        {
            "id": 142,
            "color": "RED",
            "size": "S",
            "sku": "UT-5000-RED-S"
        },
        {
            "id": 143,
            "color": "RED",
            "size": "M",
            "sku": "UT-5000-RED-M"
        },
        {
            "id": 144,
            "color": "RED",
            "size": "L",
            "sku": "UT-5000-RED-L"
        },
        {
            "id": 145,
            "color": "RED",
            "size": "XL",
            "sku": "UT-5000-RED-XL"
        },
        {
            "id": 146,
            "color": "RED",
            "size": "2XL",
            "sku": "UT-5000-RED-2XL"
        },
        {
            "id": 147,
            "color": "RED",
            "size": "3XL",
            "sku": "UT-5000-RED-3XL"
        },
        {
            "id": 136,
            "color": "PURPLE",
            "size": "S",
            "sku": "UT-5000-PUE-S"
        },
        {
            "id": 137,
            "color": "PURPLE",
            "size": "M",
            "sku": "UT-5000-PUE-M"
        },
        {
            "id": 138,
            "color": "PURPLE",
            "size": "L",
            "sku": "UT-5000-PUE-L"
        },
        {
            "id": 139,
            "color": "PURPLE",
            "size": "XL",
            "sku": "UT-5000-PUE-XL"
        },
        {
            "id": 140,
            "color": "PURPLE",
            "size": "2XL",
            "sku": "UT-5000-PUE-2XL"
        },
        {
            "id": 141,
            "color": "PURPLE",
            "size": "3XL",
            "sku": "UT-5000-PUE-3XL"
        },
        {
            "id": 124,
            "color": "NAVY",
            "size": "S",
            "sku": "UT-5000-NAY-S"
        },
        {
            "id": 125,
            "color": "NAVY",
            "size": "M",
            "sku": "UT-5000-NAY-M"
        },
        {
            "id": 126,
            "color": "NAVY",
            "size": "L",
            "sku": "UT-5000-NAY-L"
        },
        {
            "id": 127,
            "color": "NAVY",
            "size": "XL",
            "sku": "UT-5000-NAY-XL"
        },
        {
            "id": 128,
            "color": "NAVY",
            "size": "2XL",
            "sku": "UT-5000-NAY-2XL"
        },
        {
            "id": 129,
            "color": "NAVY",
            "size": "3XL",
            "sku": "UT-5000-NAY-3XL"
        },
        {
            "id": 201,
            "color": "MAROON",
            "size": "S",
            "sku": "UT-5000-MAN-S"
        },
        {
            "id": 202,
            "color": "MAROON",
            "size": "M",
            "sku": "UT-5000-MAN-M"
        },
        {
            "id": 203,
            "color": "MAROON",
            "size": "L",
            "sku": "UT-5000-MAN-L"
        },
        {
            "id": 204,
            "color": "MAROON",
            "size": "XL",
            "sku": "UT-5000-MAN-XL"
        },
        {
            "id": 205,
            "color": "MAROON",
            "size": "2XL",
            "sku": "UT-5000-MAN-2XL"
        },
        {
            "id": 206,
            "color": "MAROON",
            "size": "3XL",
            "sku": "UT-5000-MAN-3XL"
        },
        {
            "id": 3010,
            "color": "CHARCOAL",
            "size": "S",
            "sku": "UT-5000-CHL-S"
        },
        {
            "id": 3011,
            "color": "CHARCOAL",
            "size": "M",
            "sku": "UT-5000-CHL-M"
        },
        {
            "id": 3012,
            "color": "CHARCOAL",
            "size": "L",
            "sku": "UT-5000-CHL-L"
        },
        {
            "id": 3013,
            "color": "CHARCOAL",
            "size": "XL",
            "sku": "UT-5000-CHL-XL"
        },
        {
            "id": 3014,
            "color": "CHARCOAL",
            "size": "2XL",
            "sku": "UT-5000-CHL-2XL"
        },
        {
            "id": 3015,
            "color": "CHARCOAL",
            "size": "3XL",
            "sku": "UT-5000-CHL-3XL"
        },
        {
            "id": 172,
            "color": "ASH",
            "size": "S",
            "sku": "UT-5000-ASH-S"
        },
        {
            "id": 173,
            "color": "ASH",
            "size": "M",
            "sku": "UT-5000-ASH-M"
        },
        {
            "id": 174,
            "color": "ASH",
            "size": "L",
            "sku": "UT-5000-ASH-L"
        },
        {
            "id": 175,
            "color": "ASH",
            "size": "XL",
            "sku": "UT-5000-ASH-XL"
        },
        {
            "id": 176,
            "color": "ASH",
            "size": "2XL",
            "sku": "UT-5000-ASH-2XL"
        },
        {
            "id": 2730,
            "color": "ASH",
            "size": "3XL",
            "sku": "UT-5000-ASH-3XL"
        }],
        "sku": "TG5000",
        "name": "Unisex-Gildan-UnisexT-Shirt",
    },
    {
        "variations": [
            {
                "id": 2948,
                "color": "Pepper",
                "size": "M",
                "sku": "UT-1717-Per-M"
            },
            {
                "id": 2949,
                "color": "Pepper",
                "size": "L",
                "sku": "UT-1717-Per-L"
            },
            {
                "id": 2950,
                "color": "Pepper",
                "size": "XL",
                "sku": "UT-1717-Per-XL"
            },
            {
                "id": 2951,
                "color": "Pepper",
                "size": "2XL",
                "sku": "UT-1717-Per-2XL"
            },
            {
                "id": 2903,
                "color": "Black",
                "size": "M",
                "sku": "UT-1717-Blk-M"
            },
            {
                "id": 2904,
                "color": "Black",
                "size": "L",
                "sku": "UT-1717-Blk-L"
            },
            {
                "id": 2905,
                "color": "Black",
                "size": "XL",
                "sku": "UT-1717-Blk-XL"
            },
            {
                "id": 2906,
                "color": "Black",
                "size": "2XL",
                "sku": "UT-1717-Blk-2XL"
            },
            {
                "id": 2955,
                "color": "IVORY",
                "size": "M",
                "sku": "UT-1717-IVY-M"
            },
            {
                "id": 2956,
                "color": "IVORY",
                "size": "L",
                "sku": "UT-1717-IVY-L"
            },
            {
                "id": 2957,
                "color": "IVORY",
                "size": "XL",
                "sku": "UT-1717-IVY-XL"
            },
            {
                "id": 2958,
                "color": "IVORY",
                "size": "2XL",
                "sku": "UT-1717-IVY-2XL"
            },
            {
                "id": 2962,
                "color": "Orchid",
                "size": "M",
                "sku": "UT-1717-Ord-M"
            },
            {
                "id": 2963,
                "color": "Orchid",
                "size": "L",
                "sku": "UT-1717-Ord-L"
            },
            {
                "id": 2964,
                "color": "Orchid",
                "size": "XL",
                "sku": "UT-1717-Ord-XL"
            },
            {
                "id": 2969,
                "color": "White",
                "size": "M",
                "sku": "UT-1717-Whe-M"
            },
            {
                "id": 2970,
                "color": "White",
                "size": "L",
                "sku": "UT-1717-Whe-L"
            },
            {
                "id": 2971,
                "color": "White",
                "size": "XL",
                "sku": "UT-1717-Whe-XL"
            },
            {
                "id": 2972,
                "color": "White",
                "size": "2XL",
                "sku": "UT-1717-Whe-2XL"
            },
            {
                "id": 3052,
                "color": "YAM",
                "size": "S",
                "sku": "UT-1717-YAM-S"
            },
            {
                "id": 3053,
                "color": "YAM",
                "size": "M",
                "sku": "UT-1717-YAM-M"
            },
            {
                "id": 3054,
                "color": "YAM",
                "size": "L",
                "sku": "UT-1717-YAM-L"
            },
            {
                "id": 3055,
                "color": "YAM",
                "size": "XL",
                "sku": "UT-1717-YAM-XL"
            },
            {
                "id": 3056,
                "color": "YAM",
                "size": "2XL",
                "sku": "UT-1717-YAM-2XL"
            },
            {
                "id": 3024,
                "color": "NAVY",
                "size": "S",
                "sku": "UT-1717-NAY-S"
            },
            {
                "id": 3025,
                "color": "NAVY",
                "size": "M",
                "sku": "UT-1717-NAY-M"
            },
            {
                "id": 3026,
                "color": "NAVY",
                "size": "L",
                "sku": "UT-1717-NAY-L"
            },
            {
                "id": 3027,
                "color": "NAVY",
                "size": "XL",
                "sku": "UT-1717-NAY-XL"
            },
            {
                "id": 3028,
                "color": "NAVY",
                "size": "2XL",
                "sku": "UT-1717-NAY-2XL"
            },
            {
                "id": 3003,
                "color": "MOSS",
                "size": "S",
                "sku": "UT-1717-MOS-S"
            },
            {
                "id": 3004,
                "color": "MOSS",
                "size": "M",
                "sku": "UT-1717-MOS-M"
            },
            {
                "id": 3005,
                "color": "MOSS",
                "size": "L",
                "sku": "UT-1717-MOS-L"
            },
            {
                "id": 3006,
                "color": "MOSS",
                "size": "XL",
                "sku": "UT-1717-MOS-XL"
            },
            {
                "id": 3007,
                "color": "MOSS",
                "size": "2XL",
                "sku": "UT-1717-MOS-2XL"
            },
            {
                "id": 3031,
                "color": "LIGHT GREEN",
                "size": "S",
                "sku": "UT-1717-LIN-S"
            },
            {
                "id": 3032,
                "color": "LIGHT GREEN",
                "size": "M",
                "sku": "UT-1717-LIN-M"
            },
            {
                "id": 3033,
                "color": "LIGHT GREEN",
                "size": "L",
                "sku": "UT-1717-LIN-L"
            },
            {
                "id": 3034,
                "color": "LIGHT GREEN",
                "size": "XL",
                "sku": "UT-1717-LIN-XL"
            },
            {
                "id": 3035,
                "color": "LIGHT GREEN",
                "size": "2XL",
                "sku": "UT-1717-LIN-2XL"
            },
            {
                "id": 3038,
                "color": "GRAPHITE",
                "size": "S",
                "sku": "UT-1717-GRE-S"
            },
            {
                "id": 3039,
                "color": "GRAPHITE",
                "size": "M",
                "sku": "UT-1717-GRE-M"
            },
            {
                "id": 3040,
                "color": "GRAPHITE",
                "size": "L",
                "sku": "UT-1717-GRE-L"
            },
            {
                "id": 3041,
                "color": "GRAPHITE",
                "size": "XL",
                "sku": "UT-1717-GRE-XL"
            },
            {
                "id": 3042,
                "color": "GRAPHITE",
                "size": "2XL",
                "sku": "UT-1717-GRE-2XL"
            },
            {
                "id": 3045,
                "color": "BLUE JEAN",
                "size": "S",
                "sku": "UT-1717-BLN-S"
            },
            {
                "id": 3046,
                "color": "BLUE JEAN",
                "size": "M",
                "sku": "UT-1717-BLN-M"
            },
            {
                "id": 3047,
                "color": "BLUE JEAN",
                "size": "L",
                "sku": "UT-1717-BLN-L"
            },
            {
                "id": 3048,
                "color": "BLUE JEAN",
                "size": "XL",
                "sku": "UT-1717-BLN-XL"
            },
            {
                "id": 3049,
                "color": "BLUE JEAN",
                "size": "2XL",
                "sku": "UT-1717-BLN-2XL"
            },
            {
                "id": 226,
                "color": "Black",
                "size": "M",
                "sku": "SW-18000-Blk-M"
            },
            {
                "id": 227,
                "color": "Black",
                "size": "L",
                "sku": "SW-18000-Blk-L"
            },
            {
                "id": 228,
                "color": "Black",
                "size": "XL",
                "sku": "SW-18000-Blk-XL"
            },
            {
                "id": 229,
                "color": "Black",
                "size": "2XL",
                "sku": "SW-18000-Blk-2XL"
            },
            {
                "id": 250,
                "color": "White",
                "size": "M",
                "sku": "SW-18000-Whe-M"
            },
            {
                "id": 251,
                "color": "White",
                "size": "L",
                "sku": "SW-18000-Whe-L"
            },
            {
                "id": 252,
                "color": "White",
                "size": "XL",
                "sku": "SW-18000-Whe-XL"
            },
            {
                "id": 253,
                "color": "White",
                "size": "2XL",
                "sku": "SW-18000-Whe-2XL"
            },
            {
                "id": 289,
                "color": "Sand",
                "size": "M",
                "sku": "SW-18000-Sad-M"
            },
            {
                "id": 290,
                "color": "Sand",
                "size": "L",
                "sku": "SW-18000-Sad-L"
            },
            {
                "id": 291,
                "color": "Sand",
                "size": "XL",
                "sku": "SW-18000-Sad-XL"
            },
            {
                "id": 292,
                "color": "Sand",
                "size": "2XL",
                "sku": "SW-18000-Sad-2XL"
            },
            {
                "id": 219,
                "color": "SPORT GREY",
                "size": "S",
                "sku": "SW-18000-SPY-S"
            },
            {
                "id": 220,
                "color": "SPORT GREY",
                "size": "M",
                "sku": "SW-18000-SPY-M"
            },
            {
                "id": 221,
                "color": "SPORT GREY",
                "size": "L",
                "sku": "SW-18000-SPY-L"
            },
            {
                "id": 222,
                "color": "SPORT GREY",
                "size": "XL",
                "sku": "SW-18000-SPY-XL"
            },
            {
                "id": 223,
                "color": "SPORT GREY",
                "size": "2XL",
                "sku": "SW-18000-SPY-2XL"
            },
            {
                "id": 3059,
                "color": "ORANGE",
                "size": "S",
                "sku": "SW-18000-ORE-S"
            },
            {
                "id": 3060,
                "color": "ORANGE",
                "size": "M",
                "sku": "SW-18000-ORE-M"
            },
            {
                "id": 3061,
                "color": "ORANGE",
                "size": "L",
                "sku": "SW-18000-ORE-L"
            },
            {
                "id": 3062,
                "color": "ORANGE",
                "size": "XL",
                "sku": "SW-18000-ORE-XL"
            },
            {
                "id": 3063,
                "color": "ORANGE",
                "size": "2XL",
                "sku": "SW-18000-ORE-2XL"
            },
            {
                "id": 284,
                "color": "MILLITARY GREEN",
                "size": "S",
                "sku": "SW-18000-MIN-S"
            },
            {
                "id": 285,
                "color": "MILLITARY GREEN",
                "size": "M",
                "sku": "SW-18000-MIN-M"
            },
            {
                "id": 2824,
                "color": "MILLITARY GREEN",
                "size": "L",
                "sku": "SW-18000-MIN-L"
            },
            {
                "id": 286,
                "color": "MILLITARY GREEN",
                "size": "XL",
                "sku": "SW-18000-MIN-XL"
            },
            {
                "id": 2825,
                "color": "MILLITARY GREEN",
                "size": "2XL",
                "sku": "SW-18000-MIN-2XL"
            },
            {
                "id": 3066,
                "color": "FOREST GREEN",
                "size": "S",
                "sku": "SW-18000-FON-S"
            },
            {
                "id": 3067,
                "color": "FOREST GREEN",
                "size": "M",
                "sku": "SW-18000-FON-M"
            },
            {
                "id": 3068,
                "color": "FOREST GREEN",
                "size": "L",
                "sku": "SW-18000-FON-L"
            },
            {
                "id": 3069,
                "color": "FOREST GREEN",
                "size": "XL",
                "sku": "SW-18000-FON-XL"
            },
            {
                "id": 3070,
                "color": "FOREST GREEN",
                "size": "2XL",
                "sku": "SW-18000-FON-2XL"
            },
            {
                "id": 231,
                "color": "DARK HEATHER",
                "size": "S",
                "sku": "SW-18000-DAR-S"
            },
            {
                "id": 232,
                "color": "DARK HEATHER",
                "size": "M",
                "sku": "SW-18000-DAR-M"
            },
            {
                "id": 233,
                "color": "DARK HEATHER",
                "size": "L",
                "sku": "SW-18000-DAR-L"
            },
            {
                "id": 234,
                "color": "DARK HEATHER",
                "size": "XL",
                "sku": "SW-18000-DAR-XL"
            },
            {
                "id": 235,
                "color": "DARK HEATHER",
                "size": "2XL",
                "sku": "SW-18000-DAR-2XL"
            },
            {
                "id": 100,
                "color": "Black",
                "size": "S",
                "sku": "UT-5000-Blk-S"
            },
            {
                "id": 101,
                "color": "Black",
                "size": "M",
                "sku": "UT-5000-Blk-M"
            },
            {
                "id": 102,
                "color": "Black",
                "size": "L",
                "sku": "UT-5000-Blk-L"
            },
            {
                "id": 103,
                "color": "Black",
                "size": "XL",
                "sku": "UT-5000-Blk-XL"
            },
            {
                "id": 104,
                "color": "Black",
                "size": "2XL",
                "sku": "UT-5000-Blk-2XL"
            },
            {
                "id": 105,
                "color": "Black",
                "size": "3XL",
                "sku": "UT-5000-Blk-3XL"
            },
            {
                "id": 207,
                "color": "Sand",
                "size": "S",
                "sku": "UT-5000-Sad-S"
            },
            {
                "id": 208,
                "color": "Sand",
                "size": "M",
                "sku": "UT-5000-Sad-M"
            },
            {
                "id": 209,
                "color": "Sand",
                "size": "L",
                "sku": "UT-5000-Sad-L"
            },
            {
                "id": 210,
                "color": "Sand",
                "size": "XL",
                "sku": "UT-5000-Sad-XL"
            },
            {
                "id": 211,
                "color": "Sand",
                "size": "2XL",
                "sku": "UT-5000-Sad-2XL"
            },
            {
                "id": 212,
                "color": "Sand",
                "size": "3XL",
                "sku": "UT-5000-Sad-3XL"
            },
            {
                "id": 154,
                "color": "White",
                "size": "S",
                "sku": "UT-5000-Whe-S"
            },
            {
                "id": 155,
                "color": "White",
                "size": "M",
                "sku": "UT-5000-Whe-M"
            },
            {
                "id": 156,
                "color": "White",
                "size": "L",
                "sku": "UT-5000-Whe-L"
            },
            {
                "id": 157,
                "color": "White",
                "size": "XL",
                "sku": "UT-5000-Whe-XL"
            },
            {
                "id": 158,
                "color": "White",
                "size": "2XL",
                "sku": "UT-5000-Whe-2XL"
            },
            {
                "id": 159,
                "color": "White",
                "size": "3XL",
                "sku": "UT-5000-Whe-3XL"
            },
            {
                "id": 160,
                "color": "SPORT GREY",
                "size": "S",
                "sku": "UT-5000-SPY-S"
            },
            {
                "id": 161,
                "color": "SPORT GREY",
                "size": "M",
                "sku": "UT-5000-SPY-M"
            },
            {
                "id": 162,
                "color": "SPORT GREY",
                "size": "L",
                "sku": "UT-5000-SPY-L"
            },
            {
                "id": 163,
                "color": "SPORT GREY",
                "size": "XL",
                "sku": "UT-5000-SPY-XL"
            },
            {
                "id": 164,
                "color": "SPORT GREY",
                "size": "2XL",
                "sku": "UT-5000-SPY-2XL"
            },
            {
                "id": 165,
                "color": "SPORT GREY",
                "size": "3XL",
                "sku": "UT-5000-SPY-3XL"
            },
            {
                "id": 166,
                "color": "DARK HEATHER",
                "size": "S",
                "sku": "UT-5000-DAR-S"
            },
            {
                "id": 167,
                "color": "DARK HEATHER",
                "size": "M",
                "sku": "UT-5000-DAR-M"
            },
            {
                "id": 168,
                "color": "DARK HEATHER",
                "size": "L",
                "sku": "UT-5000-DAR-L"
            },
            {
                "id": 169,
                "color": "DARK HEATHER",
                "size": "XL",
                "sku": "UT-5000-DAR-XL"
            },
            {
                "id": 170,
                "color": "DARK HEATHER",
                "size": "2XL",
                "sku": "UT-5000-DAR-2XL"
            },
            {
                "id": 171,
                "color": "DARK HEATHER",
                "size": "3XL",
                "sku": "UT-5000-DAR-3XL"
            },
            {
                "id": 2984,
                "color": "FOREST GREEN",
                "size": "S",
                "sku": "UT-5000-FON-S"
            },
            {
                "id": 2845,
                "color": "FOREST GREEN",
                "size": "M",
                "sku": "UT-5000-FON-M"
            },
            {
                "id": 2991,
                "color": "FOREST GREEN",
                "size": "L",
                "sku": "UT-5000-FON-L"
            },
            {
                "id": 2998,
                "color": "FOREST GREEN",
                "size": "XL",
                "sku": "UT-5000-FON-XL"
            },
            {
                "id": 2831,
                "color": "FOREST GREEN",
                "size": "2XL",
                "sku": "UT-5000-FON-2XL"
            },
            {
                "id": 2835,
                "color": "FOREST GREEN",
                "size": "3XL",
                "sku": "UT-5000-FON-3XL"
            },
            {
                "id": 112,
                "color": "LIGHT BLUE",
                "size": "S",
                "sku": "UT-5000-LIE-S"
            },
            {
                "id": 113,
                "color": "LIGHT BLUE",
                "size": "M",
                "sku": "UT-5000-LIE-M"
            },
            {
                "id": 114,
                "color": "LIGHT BLUE",
                "size": "L",
                "sku": "UT-5000-LIE-L"
            },
            {
                "id": 115,
                "color": "LIGHT BLUE",
                "size": "XL",
                "sku": "UT-5000-LIE-XL"
            },
            {
                "id": 116,
                "color": "LIGHT BLUE",
                "size": "2XL",
                "sku": "UT-5000-LIE-2XL"
            },
            {
                "id": 117,
                "color": "LIGHT BLUE",
                "size": "3XL",
                "sku": "UT-5000-LIE-3XL"
            },
            {
                "id": 195,
                "color": "LIGHT PINK",
                "size": "S",
                "sku": "UT-5000-LIK-S"
            },
            {
                "id": 196,
                "color": "LIGHT PINK",
                "size": "M",
                "sku": "UT-5000-LIK-M"
            },
            {
                "id": 197,
                "color": "LIGHT PINK",
                "size": "L",
                "sku": "UT-5000-LIK-L"
            },
            {
                "id": 198,
                "color": "LIGHT PINK",
                "size": "XL",
                "sku": "UT-5000-LIK-XL"
            },
            {
                "id": 199,
                "color": "LIGHT PINK",
                "size": "2XL",
                "sku": "UT-5000-LIK-2XL"
            },
            {
                "id": 200,
                "color": "LIGHT PINK",
                "size": "3XL",
                "sku": "UT-5000-LIK-3XL"
            },
            {
                "id": 3017,
                "color": "ROYAL",
                "size": "S",
                "sku": "UT-5000-ROL-S"
            },
            {
                "id": 3018,
                "color": "ROYAL",
                "size": "M",
                "sku": "UT-5000-ROL-M"
            },
            {
                "id": 3019,
                "color": "ROYAL",
                "size": "L",
                "sku": "UT-5000-ROL-L"
            },
            {
                "id": 3020,
                "color": "ROYAL",
                "size": "XL",
                "sku": "UT-5000-ROL-XL"
            },
            {
                "id": 3021,
                "color": "ROYAL",
                "size": "2XL",
                "sku": "UT-5000-ROL-2XL"
            },
            {
                "id": 3022,
                "color": "ROYAL",
                "size": "3XL",
                "sku": "UT-5000-ROL-3XL"
            },
            {
                "id": 142,
                "color": "RED",
                "size": "S",
                "sku": "UT-5000-RED-S"
            },
            {
                "id": 143,
                "color": "RED",
                "size": "M",
                "sku": "UT-5000-RED-M"
            },
            {
                "id": 144,
                "color": "RED",
                "size": "L",
                "sku": "UT-5000-RED-L"
            },
            {
                "id": 145,
                "color": "RED",
                "size": "XL",
                "sku": "UT-5000-RED-XL"
            },
            {
                "id": 146,
                "color": "RED",
                "size": "2XL",
                "sku": "UT-5000-RED-2XL"
            },
            {
                "id": 147,
                "color": "RED",
                "size": "3XL",
                "sku": "UT-5000-RED-3XL"
            },
            {
                "id": 136,
                "color": "PURPLE",
                "size": "S",
                "sku": "UT-5000-PUE-S"
            },
            {
                "id": 137,
                "color": "PURPLE",
                "size": "M",
                "sku": "UT-5000-PUE-M"
            },
            {
                "id": 138,
                "color": "PURPLE",
                "size": "L",
                "sku": "UT-5000-PUE-L"
            },
            {
                "id": 139,
                "color": "PURPLE",
                "size": "XL",
                "sku": "UT-5000-PUE-XL"
            },
            {
                "id": 140,
                "color": "PURPLE",
                "size": "2XL",
                "sku": "UT-5000-PUE-2XL"
            },
            {
                "id": 141,
                "color": "PURPLE",
                "size": "3XL",
                "sku": "UT-5000-PUE-3XL"
            },
            {
                "id": 124,
                "color": "NAVY",
                "size": "S",
                "sku": "UT-5000-NAY-S"
            },
            {
                "id": 125,
                "color": "NAVY",
                "size": "M",
                "sku": "UT-5000-NAY-M"
            },
            {
                "id": 126,
                "color": "NAVY",
                "size": "L",
                "sku": "UT-5000-NAY-L"
            },
            {
                "id": 127,
                "color": "NAVY",
                "size": "XL",
                "sku": "UT-5000-NAY-XL"
            },
            {
                "id": 128,
                "color": "NAVY",
                "size": "2XL",
                "sku": "UT-5000-NAY-2XL"
            },
            {
                "id": 129,
                "color": "NAVY",
                "size": "3XL",
                "sku": "UT-5000-NAY-3XL"
            },
            {
                "id": 201,
                "color": "MAROON",
                "size": "S",
                "sku": "UT-5000-MAN-S"
            },
            {
                "id": 202,
                "color": "MAROON",
                "size": "M",
                "sku": "UT-5000-MAN-M"
            },
            {
                "id": 203,
                "color": "MAROON",
                "size": "L",
                "sku": "UT-5000-MAN-L"
            },
            {
                "id": 204,
                "color": "MAROON",
                "size": "XL",
                "sku": "UT-5000-MAN-XL"
            },
            {
                "id": 205,
                "color": "MAROON",
                "size": "2XL",
                "sku": "UT-5000-MAN-2XL"
            },
            {
                "id": 206,
                "color": "MAROON",
                "size": "3XL",
                "sku": "UT-5000-MAN-3XL"
            },
            {
                "id": 3010,
                "color": "CHARCOAL",
                "size": "S",
                "sku": "UT-5000-CHL-S"
            },
            {
                "id": 3011,
                "color": "CHARCOAL",
                "size": "M",
                "sku": "UT-5000-CHL-M"
            },
            {
                "id": 3012,
                "color": "CHARCOAL",
                "size": "L",
                "sku": "UT-5000-CHL-L"
            },
            {
                "id": 3013,
                "color": "CHARCOAL",
                "size": "XL",
                "sku": "UT-5000-CHL-XL"
            },
            {
                "id": 3014,
                "color": "CHARCOAL",
                "size": "2XL",
                "sku": "UT-5000-CHL-2XL"
            },
            {
                "id": 3015,
                "color": "CHARCOAL",
                "size": "3XL",
                "sku": "UT-5000-CHL-3XL"
            },
            {
                "id": 172,
                "color": "ASH",
                "size": "S",
                "sku": "UT-5000-ASH-S"
            },
            {
                "id": 173,
                "color": "ASH",
                "size": "M",
                "sku": "UT-5000-ASH-M"
            },
            {
                "id": 174,
                "color": "ASH",
                "size": "L",
                "sku": "UT-5000-ASH-L"
            },
            {
                "id": 175,
                "color": "ASH",
                "size": "XL",
                "sku": "UT-5000-ASH-XL"
            },
            {
                "id": 176,
                "color": "ASH",
                "size": "2XL",
                "sku": "UT-5000-ASH-2XL"
            },
            {
                "id": 2730,
                "color": "ASH",
                "size": "3XL",
                "sku": "UT-5000-ASH-3XL"
            },
            {
                "id": 3114,
                "color": "BLACK",
                "size": "S",
                "sku": "YT-5000B-BLK-S"
            },
            {
                "id": 3115,
                "color": "BLACK",
                "size": "M",
                "sku": "YT-5000B-BLK-M"
            },
            {
                "id": 3116,
                "color": "BLACK",
                "size": "L",
                "sku": "YT-5000B-BLK-L"
            },
            {
                "id": 3117,
                "color": "BLACK",
                "size": "XL",
                "sku": "YT-5000B-BLK-XL"
            },
            {
                "id": 3121,
                "color": "WHITE",
                "size": "S",
                "sku": "YT-5000B-WHE-S"
            },
            {
                "id": 3122,
                "color": "WHITE",
                "size": "M",
                "sku": "YT-5000B-WHE-M"
            },
            {
                "id": 3123,
                "color": "WHITE",
                "size": "L",
                "sku": "YT-5000B-WHE-L"
            },
            {
                "id": 3124,
                "color": "WHITE",
                "size": "XL",
                "sku": "YT-5000B-WHE-XL"
            },
            {
                "id": 3128,
                "color": "SAND",
                "size": "S",
                "sku": "YT-5000B-SAD-S"
            },
            {
                "id": 3129,
                "color": "SAND",
                "size": "M",
                "sku": "YT-5000B-SAD-M"
            },
            {
                "id": 3130,
                "color": "SAND",
                "size": "L",
                "sku": "YT-5000B-SAD-L"
            },
            {
                "id": 3131,
                "color": "SAND",
                "size": "XL",
                "sku": "YT-5000B-SAD-XL"
            },
            {
                "id": 3135,
                "color": "RED",
                "size": "S",
                "sku": "YT-5000B-RED-S"
            },
            {
                "id": 3136,
                "color": "RED",
                "size": "M",
                "sku": "YT-5000B-RED-M"
            },
            {
                "id": 3137,
                "color": "RED",
                "size": "L",
                "sku": "YT-5000B-RED-L"
            },
            {
                "id": 3138,
                "color": "RED",
                "size": "XL",
                "sku": "YT-5000B-RED-XL"
            },
            {
                "id": 3142,
                "color": "SPORT GREY",
                "size": "S",
                "sku": "YT-5000B-SPY-S"
            },
            {
                "id": 3143,
                "color": "SPORT GREY",
                "size": "M",
                "sku": "YT-5000B-SPY-M"
            },
            {
                "id": 3144,
                "color": "SPORT GREY",
                "size": "L",
                "sku": "YT-5000B-SPY-L"
            },
            {
                "id": 3145,
                "color": "SPORT GREY",
                "size": "XL",
                "sku": "YT-5000B-SPY-XL"
            },
            {
                "id": 3149,
                "color": "DARK HEATHER",
                "size": "S",
                "sku": "YT-5000B-DAR-S"
            },
            {
                "id": 3150,
                "color": "DARK HEATHER",
                "size": "M",
                "sku": "YT-5000B-DAR-M"
            },
            {
                "id": 3151,
                "color": "DARK HEATHER",
                "size": "L",
                "sku": "YT-5000B-DAR-L"
            },
            {
                "id": 3152,
                "color": "DARK HEATHER",
                "size": "XL",
                "sku": "YT-5000B-DAR-XL"
            },
            {
                "id": 307,
                "color": "Black",
                "size": "M",
                "sku": "HG-18500-Blk-M"
            },
            {
                "id": 308,
                "color": "Black",
                "size": "L",
                "sku": "HG-18500-Blk-L"
            },
            {
                "id": 309,
                "color": "Black",
                "size": "XL",
                "sku": "HG-18500-Blk-XL"
            },
            {
                "id": 310,
                "color": "Black",
                "size": "2XL",
                "sku": "HG-18500-Blk-2XL"
            },
            {
                "id": 349,
                "color": "White",
                "size": "M",
                "sku": "HG-18500-Whe-M"
            },
            {
                "id": 350,
                "color": "White",
                "size": "L",
                "sku": "HG-18500-Whe-L"
            },
            {
                "id": 351,
                "color": "White",
                "size": "XL",
                "sku": "HG-18500-Whe-XL"
            },
            {
                "id": 352,
                "color": "White",
                "size": "2XL",
                "sku": "HG-18500-Whe-2XL"
            },
            {
                "id": 355,
                "color": "Sand",
                "size": "M",
                "sku": "HG-18500-Sad-M"
            },
            {
                "id": 356,
                "color": "Sand",
                "size": "L",
                "sku": "HG-18500-Sad-L"
            },
            {
                "id": 357,
                "color": "Sand",
                "size": "XL",
                "sku": "HG-18500-Sad-XL"
            },
            {
                "id": 358,
                "color": "Sand",
                "size": "2XL",
                "sku": "HG-18500-Sad-2XL"
            }
        ],
        "name": "Unisex-ComfortColor-UnisexT-Shirt",
        "sku": "TC1717",
    },
    {
        "variations": [{
            "id": 226,
            "color": "Black",
            "size": "M",
            "sku": "SW-18000-Blk-M"
        },
        {
            "id": 227,
            "color": "Black",
            "size": "L",
            "sku": "SW-18000-Blk-L"
        },
        {
            "id": 228,
            "color": "Black",
            "size": "XL",
            "sku": "SW-18000-Blk-XL"
        },
        {
            "id": 229,
            "color": "Black",
            "size": "2XL",
            "sku": "SW-18000-Blk-2XL"
        },
        {
            "id": 250,
            "color": "White",
            "size": "M",
            "sku": "SW-18000-Whe-M"
        },
        {
            "id": 251,
            "color": "White",
            "size": "L",
            "sku": "SW-18000-Whe-L"
        },
        {
            "id": 252,
            "color": "White",
            "size": "XL",
            "sku": "SW-18000-Whe-XL"
        },
        {
            "id": 253,
            "color": "White",
            "size": "2XL",
            "sku": "SW-18000-Whe-2XL"
        },
        {
            "id": 289,
            "color": "Sand",
            "size": "M",
            "sku": "SW-18000-Sad-M"
        },
        {
            "id": 290,
            "color": "Sand",
            "size": "L",
            "sku": "SW-18000-Sad-L"
        },
        {
            "id": 291,
            "color": "Sand",
            "size": "XL",
            "sku": "SW-18000-Sad-XL"
        },
        {
            "id": 292,
            "color": "Sand",
            "size": "2XL",
            "sku": "SW-18000-Sad-2XL"
        },
        {
            "id": 219,
            "color": "SPORT GREY",
            "size": "S",
            "sku": "SW-18000-SPY-S"
        },
        {
            "id": 220,
            "color": "SPORT GREY",
            "size": "M",
            "sku": "SW-18000-SPY-M"
        },
        {
            "id": 221,
            "color": "SPORT GREY",
            "size": "L",
            "sku": "SW-18000-SPY-L"
        },
        {
            "id": 222,
            "color": "SPORT GREY",
            "size": "XL",
            "sku": "SW-18000-SPY-XL"
        },
        {
            "id": 223,
            "color": "SPORT GREY",
            "size": "2XL",
            "sku": "SW-18000-SPY-2XL"
        },
        {
            "id": 3059,
            "color": "ORANGE",
            "size": "S",
            "sku": "SW-18000-ORE-S"
        },
        {
            "id": 3060,
            "color": "ORANGE",
            "size": "M",
            "sku": "SW-18000-ORE-M"
        },
        {
            "id": 3061,
            "color": "ORANGE",
            "size": "L",
            "sku": "SW-18000-ORE-L"
        },
        {
            "id": 3062,
            "color": "ORANGE",
            "size": "XL",
            "sku": "SW-18000-ORE-XL"
        },
        {
            "id": 3063,
            "color": "ORANGE",
            "size": "2XL",
            "sku": "SW-18000-ORE-2XL"
        },
        {
            "id": 284,
            "color": "MILLITARY GREEN",
            "size": "S",
            "sku": "SW-18000-MIN-S"
        },
        {
            "id": 285,
            "color": "MILLITARY GREEN",
            "size": "M",
            "sku": "SW-18000-MIN-M"
        },
        {
            "id": 2824,
            "color": "MILLITARY GREEN",
            "size": "L",
            "sku": "SW-18000-MIN-L"
        },
        {
            "id": 286,
            "color": "MILLITARY GREEN",
            "size": "XL",
            "sku": "SW-18000-MIN-XL"
        },
        {
            "id": 2825,
            "color": "MILLITARY GREEN",
            "size": "2XL",
            "sku": "SW-18000-MIN-2XL"
        },
        {
            "id": 3066,
            "color": "FOREST GREEN",
            "size": "S",
            "sku": "SW-18000-FON-S"
        },
        {
            "id": 3067,
            "color": "FOREST GREEN",
            "size": "M",
            "sku": "SW-18000-FON-M"
        },
        {
            "id": 3068,
            "color": "FOREST GREEN",
            "size": "L",
            "sku": "SW-18000-FON-L"
        },
        {
            "id": 3069,
            "color": "FOREST GREEN",
            "size": "XL",
            "sku": "SW-18000-FON-XL"
        },
        {
            "id": 3070,
            "color": "FOREST GREEN",
            "size": "2XL",
            "sku": "SW-18000-FON-2XL"
        },
        {
            "id": 231,
            "color": "DARK HEATHER",
            "size": "S",
            "sku": "SW-18000-DAR-S"
        },
        {
            "id": 232,
            "color": "DARK HEATHER",
            "size": "M",
            "sku": "SW-18000-DAR-M"
        },
        {
            "id": 233,
            "color": "DARK HEATHER",
            "size": "L",
            "sku": "SW-18000-DAR-L"
        },
        {
            "id": 234,
            "color": "DARK HEATHER",
            "size": "XL",
            "sku": "SW-18000-DAR-XL"
        },
        {
            "id": 235,
            "color": "DARK HEATHER",
            "size": "2XL",
            "sku": "SW-18000-DAR-2XL"
        }],
        "name": "Unisex-Gildan-Sweatshirt",
        "sku": "SG18000",
    },
    {
        "variations": [{
            "id": 307,
            "color": "Black",
            "size": "M",
            "sku": "HG-18500-Blk-M"
        },
        {
            "id": 308,
            "color": "Black",
            "size": "L",
            "sku": "HG-18500-Blk-L"
        },
        {
            "id": 309,
            "color": "Black",
            "size": "XL",
            "sku": "HG-18500-Blk-XL"
        },
        {
            "id": 310,
            "color": "Black",
            "size": "2XL",
            "sku": "HG-18500-Blk-2XL"
        },
        {
            "id": 349,
            "color": "White",
            "size": "M",
            "sku": "HG-18500-Whe-M"
        },
        {
            "id": 350,
            "color": "White",
            "size": "L",
            "sku": "HG-18500-Whe-L"
        },
        {
            "id": 351,
            "color": "White",
            "size": "XL",
            "sku": "HG-18500-Whe-XL"
        },
        {
            "id": 352,
            "color": "White",
            "size": "2XL",
            "sku": "HG-18500-Whe-2XL"
        },
        {
            "id": 355,
            "color": "Sand",
            "size": "M",
            "sku": "HG-18500-Sad-M"
        },
        {
            "id": 356,
            "color": "Sand",
            "size": "L",
            "sku": "HG-18500-Sad-L"
        },
        {
            "id": 357,
            "color": "Sand",
            "size": "XL",
            "sku": "HG-18500-Sad-XL"
        },
        {
            "id": 358,
            "color": "Sand",
            "size": "2XL",
            "sku": "HG-18500-Sad-2XL"
        }],
        "name": "Unisex-Gildan-Hoodie",
        "sku": "HG18500",
    },
    {
        "variations": [{
            "id": 3114,
            "color": "BLACK",
            "size": "S",
            "sku": "YT-5000B-BLK-S"
        },
        {
            "id": 3115,
            "color": "BLACK",
            "size": "M",
            "sku": "YT-5000B-BLK-M"
        },
        {
            "id": 3116,
            "color": "BLACK",
            "size": "L",
            "sku": "YT-5000B-BLK-L"
        },
        {
            "id": 3117,
            "color": "BLACK",
            "size": "XL",
            "sku": "YT-5000B-BLK-XL"
        },
        {
            "id": 3121,
            "color": "WHITE",
            "size": "S",
            "sku": "YT-5000B-WHE-S"
        },
        {
            "id": 3122,
            "color": "WHITE",
            "size": "M",
            "sku": "YT-5000B-WHE-M"
        },
        {
            "id": 3123,
            "color": "WHITE",
            "size": "L",
            "sku": "YT-5000B-WHE-L"
        },
        {
            "id": 3124,
            "color": "WHITE",
            "size": "XL",
            "sku": "YT-5000B-WHE-XL"
        },
        {
            "id": 3128,
            "color": "SAND",
            "size": "S",
            "sku": "YT-5000B-SAD-S"
        },
        {
            "id": 3129,
            "color": "SAND",
            "size": "M",
            "sku": "YT-5000B-SAD-M"
        },
        {
            "id": 3130,
            "color": "SAND",
            "size": "L",
            "sku": "YT-5000B-SAD-L"
        },
        {
            "id": 3131,
            "color": "SAND",
            "size": "XL",
            "sku": "YT-5000B-SAD-XL"
        },
        {
            "id": 3135,
            "color": "RED",
            "size": "S",
            "sku": "YT-5000B-RED-S"
        },
        {
            "id": 3136,
            "color": "RED",
            "size": "M",
            "sku": "YT-5000B-RED-M"
        },
        {
            "id": 3137,
            "color": "RED",
            "size": "L",
            "sku": "YT-5000B-RED-L"
        },
        {
            "id": 3138,
            "color": "RED",
            "size": "XL",
            "sku": "YT-5000B-RED-XL"
        },
        {
            "id": 3142,
            "color": "SPORT GREY",
            "size": "S",
            "sku": "YT-5000B-SPY-S"
        },
        {
            "id": 3143,
            "color": "SPORT GREY",
            "size": "M",
            "sku": "YT-5000B-SPY-M"
        },
        {
            "id": 3144,
            "color": "SPORT GREY",
            "size": "L",
            "sku": "YT-5000B-SPY-L"
        },
        {
            "id": 3145,
            "color": "SPORT GREY",
            "size": "XL",
            "sku": "YT-5000B-SPY-XL"
        },
        {
            "id": 3149,
            "color": "DARK HEATHER",
            "size": "S",
            "sku": "YT-5000B-DAR-S"
        },
        {
            "id": 3150,
            "color": "DARK HEATHER",
            "size": "M",
            "sku": "YT-5000B-DAR-M"
        },
        {
            "id": 3151,
            "color": "DARK HEATHER",
            "size": "L",
            "sku": "YT-5000B-DAR-L"
        },
        {
            "id": 3152,
            "color": "DARK HEATHER",
            "size": "XL",
            "sku": "YT-5000B-DAR-XL"
        }],
        "name": "Youth-Gildan-YouthT-Shirt",
        "sku": "5000B",
    },
    {
        "variations": [{
            "id": 3072,
            "color": "BLACK",
            "size": "S",
            "sku": "YT-3001Y-BLK-S"
        },
        {
            "id": 3073,
            "color": "BLACK",
            "size": "M",
            "sku": "YT-3001Y-BLK-M"
        },
        {
            "id": 3074,
            "color": "BLACK",
            "size": "L",
            "sku": "YT-3001Y-BLK-L"
        },
        {
            "id": 3075,
            "color": "BLACK",
            "size": "XL",
            "sku": "YT-3001Y-BLK-XL"
        },
        {
            "id": 3079,
            "color": "WHITE",
            "size": "S",
            "sku": "YT-3001Y-WHE-S"
        },
        {
            "id": 3080,
            "color": "WHITE",
            "size": "M",
            "sku": "YT-3001Y-WHE-M"
        },
        {
            "id": 3081,
            "color": "WHITE",
            "size": "L",
            "sku": "YT-3001Y-WHE-L"
        },
        {
            "id": 3082,
            "color": "WHITE",
            "size": "XL",
            "sku": "YT-3001Y-WHE-XL"
        },
        {
            "id": 3086,
            "color": "NATURAL",
            "size": "S",
            "sku": "YT-3001Y-NAL-S"
        },
        {
            "id": 3087,
            "color": "NATURAL",
            "size": "M",
            "sku": "YT-3001Y-NAL-M"
        },
        {
            "id": 3088,
            "color": "NATURAL",
            "size": "L",
            "sku": "YT-3001Y-NAL-L"
        },
        {
            "id": 3089,
            "color": "NATURAL",
            "size": "XL",
            "sku": "YT-3001Y-NAL-XL"
        },
        {
            "id": 3093,
            "color": "TRUE ROYAL",
            "size": "S",
            "sku": "YT-3001Y-TRL-S"
        },
        {
            "id": 3094,
            "color": "TRUE ROYAL",
            "size": "M",
            "sku": "YT-3001Y-TRL-M"
        },
        {
            "id": 3095,
            "color": "TRUE ROYAL",
            "size": "L",
            "sku": "YT-3001Y-TRL-L"
        },
        {
            "id": 3096,
            "color": "TRUE ROYAL",
            "size": "XL",
            "sku": "YT-3001Y-TRL-XL"
        },
        {
            "id": 3100,
            "color": "DARK GREY",
            "size": "S",
            "sku": "YT-3001Y-DAY-S"
        },
        {
            "id": 3101,
            "color": "DARK GREY",
            "size": "M",
            "sku": "YT-3001Y-DAY-M"
        },
        {
            "id": 3102,
            "color": "DARK GREY",
            "size": "L",
            "sku": "YT-3001Y-DAY-L"
        },
        {
            "id": 3103,
            "color": "DARK GREY",
            "size": "XL",
            "sku": "YT-3001Y-DAY-XL"
        },
        {
            "id": 3107,
            "color": "PINK",
            "size": "S",
            "sku": "YT-3001Y-PIK-S"
        },
        {
            "id": 3108,
            "color": "PINK",
            "size": "M",
            "sku": "YT-3001Y-PIK-M"
        },
        {
            "id": 3109,
            "color": "PINK",
            "size": "L",
            "sku": "YT-3001Y-PIK-L"
        },
        {
            "id": 3110,
            "color": "PINK",
            "size": "XL",
            "sku": "YT-3001Y-PIK-XL"
        }],
        "name": "Youth-BellaCanvas-YouthT-Shirt",
        "sku": "3001Y",
    },
    {
        "variations": [{
            "id": 2865,
            "color": "BLACK",
            "size": "M",
            "sku": "UT-3001-BLK-M"
        },
        {
            "id": 2909,
            "color": "BLACK",
            "size": "L",
            "sku": "UT-3001-BLK-L"
        },
        {
            "id": 2928,
            "color": "BLACK",
            "size": "XL",
            "sku": "UT-3001-BLK-XL"
        },
        {
            "id": 2871,
            "color": "White",
            "size": "M",
            "sku": "UT-3001-Whe-M"
        },
        {
            "id": 2915,
            "color": "White",
            "size": "L",
            "sku": "UT-3001-Whe-L"
        },
        {
            "id": 2934,
            "color": "White",
            "size": "XL",
            "sku": "UT-3001-Whe-XL"
        },
        {
            "id": 2976,
            "color": "NATURAL",
            "size": "M",
            "sku": "UT-3001-NAL-M"
        },
        {
            "id": 2977,
            "color": "NATURAL",
            "size": "L",
            "sku": "UT-3001-NAL-L"
        },
        {
            "id": 2978,
            "color": "NATURAL",
            "size": "XL",
            "sku": "UT-3001-NAL-XL"
        }],
        "name": "Unisex-BellaCanvas-UnisexT-Shirt",
        "sku": "3001",
    },
    {
        "variations": [
            {
                "id": 3156,
                "color": "BLACK",
                "size": "S",
                "sku": "WT-5000L-BLK-S"
            },
            {
                "id": 3157,
                "color": "BLACK",
                "size": "M",
                "sku": "WT-5000L-BLK-M"
            },
            {
                "id": 3158,
                "color": "BLACK",
                "size": "L",
                "sku": "WT-5000L-BLK-L"
            },
            {
                "id": 3159,
                "color": "BLACK",
                "size": "XL",
                "sku": "WT-5000L-BLK-XL"
            },
            {
                "id": 3160,
                "color": "BLACK",
                "size": "2XL",
                "sku": "WT-5000L-BLK-2XL"
            },
            {
                "id": 3161,
                "color": "BLACK",
                "size": "3XL",
                "sku": "WT-5000L-BLK-3XL"
            },
            {
                "id": 3163,
                "color": "WHITE",
                "size": "S",
                "sku": "WT-5000L-WHE-S"
            },
            {
                "id": 3164,
                "color": "WHITE",
                "size": "M",
                "sku": "WT-5000L-WHE-M"
            },
            {
                "id": 3165,
                "color": "WHITE",
                "size": "L",
                "sku": "WT-5000L-WHE-L"
            },
            {
                "id": 3166,
                "color": "WHITE",
                "size": "XL",
                "sku": "WT-5000L-WHE-XL"
            },
            {
                "id": 3167,
                "color": "WHITE",
                "size": "2XL",
                "sku": "WT-5000L-WHE-2XL"
            },
            {
                "id": 3168,
                "color": "WHITE",
                "size": "3XL",
                "sku": "WT-5000L-WHE-3XL"
            }
        ],
        "name": "Women-Gildan-WomenT-Shirt",
        "sku": "5000L",
    }

]
export default beeful

const merchfox = [
    {
        "id": "659d650c8257f",
        "name": "Sweatshirt 2D US",
        "sku": "Unisex-Gildan-Sweatshirt",
        "color": [
            "BLACK",
            "FOREST GREEN",
            "SAND",
            "SPORT GREY",
            "LIGHT PINK",
            "MILITARY GREEN",
            "MAROON",
            "RED",
            "ROYAL BLUE",
            "ASH GREY",
            "WHITE",
            "ORANGE"
        ],
        "size": [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ]
    },
    {
        "id": "659d64fa2f51e",
        "name": "Hoodie 2D US",
        "sku": "Unisex-Gildan-Hoodie",
        "color": [
            "BLACK",
            "WHITE",
            "NAVY",
            "SPORT GREY",
            "SAND",
            "FOREST GREEN",
            "LIGHT PINK"
        ],
        "size": [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ]
    },
    {
        "id": "7a2b744651",
        "name": "Bella Canvas 3001 T-shirt 2D US",
        "sku": "Unisex-BellaCanvas-UnisexT-Shirt",
        "color": [
            "Black",
            "White",
            "Dark Grey Heather",
            "Natural"
        ],
        "size": [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ]
    },
    {
        "id": "659d64e4e2287",
        "name": "T-Shirt 2D US",
        "sku": "Unisex-Gildan-UnisexT-Shirt",
        "color": [
            "BLACK",
            "WHITE",
            "LIGHT BLUE",
            "CHARCOAL",
            "NAVY",
            "RED",
            "SPORT GREY",
            "MAROON",
            "DARK HEATHER",
            "ROYAL BLUE",
            "LIGHT PINK",
            "SAND",
            "FOREST GREEN",
            "ASH GREY",
            "PURPLE",
            "MILITARY GREEN",
            "ORANGE"
        ],
        "size": [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ]
    },
    {
        "id": "58dda42030",
        "name": "Comfort 1717 T-Shirt 2D US",
        "sku": "Unisex-ComfortColor-UnisexT-Shirt",
        "color": [
            "Black",
            "White",
            "Pepper",
            "Ivory"
        ],
        "size": [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ]
    },
    {
        "id": "53eb930cfc",
        "name": "Adult Tank Top G2200 2D US",
        "sku": "Unisex-Gildan-TankTop",
        "color": [
            "BLACK",
            "WHITE",
            "SPORT GREY",
            "NAVY"
        ],
        "size": [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL"
        ]
    },
    {
        "id": "b469bda85c",
        "name": "3D-TQ CROCS (F51)",
        "sku": "Crocs",
        "color": [
            "3D Printed"
        ],
        "size": [
            ["36", "US5"],
            ["37", "US6"],
            ["38", "US6.5"],
            ["39", "US7"],
            ["40", "US8"],
            ["41", "US9"],
            ["42", "US9.5"],
            ["43", "US10"],
            ["44", "US11"],
            ["45", "US11.5"],
            ["46", "US12"],
            ["47", "US13"],
            ["48", "US14"]
        ]
    },

]

export default merchfox
/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-datepicker/dist/react-datepicker.css";
// Data

import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import { getRecord } from "features/slices";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MDBadge from "components/MDBadge";
function History() {
  const record = useSelector((state) => state.user.record || []);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      getRecord())
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "time", accessor: "time", align: "center" },
                      { Header: "collection", accessor: "collection", align: "left" },
                      { Header: "user", accessor: "user", align: "left" },
                      { Header: "content", accessor: "content", align: "left" },
                      { Header: "id data", accessor: "id_data", align: "left" },
                    ],
                    rows: record && record.length > 0 ? [...record.map((items, index) => ({
                      id: items.id,
                      time: <span className="caption-text" >{moment(items.createdAt).format("HH:mm DD/MM/YYYY")}</span>,
                      collection: <MDBadge badgeContent={items?.collection} color="success" variant="gradient" size="sm" max={99999999999999999999999999999} />,
                      user: items?.user,
                      content: items?.note,
                      id_data: items?.recordId.join("\n"),
                    })
                    )] : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default History;

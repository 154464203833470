const beeful = [
    {
        "variations": [{
            "id": 100,
            "color": "BLACK",
            "size": "S",
            "sku": "UT-5000-BLK-S"
        },
        {
            "id": 101,
            "color": "BLACK",
            "size": "M",
            "sku": "UT-5000-BLK-M"
        },
        {
            "id": 102,
            "color": "BLACK",
            "size": "L",
            "sku": "UT-5000-BLK-L"
        },
        {
            "id": 103,
            "color": "BLACK",
            "size": "XL",
            "sku": "UT-5000-BLK-XL"
        },
        {
            "id": 104,
            "color": "BLACK",
            "size": "2XL",
            "sku": "UT-5000-BLK-2XL"
        },
        {
            "id": 105,
            "color": "BLACK",
            "size": "3XL",
            "sku": "UT-5000-BLK-3XL"
        },
        {
            "id": 207,
            "color": "SAND",
            "size": "S",
            "sku": "UT-5000-SAD-S"
        },
        {
            "id": 208,
            "color": "SAND",
            "size": "M",
            "sku": "UT-5000-SAD-M"
        },
        {
            "id": 209,
            "color": "SAND",
            "size": "L",
            "sku": "UT-5000-SAD-L"
        },
        {
            "id": 210,
            "color": "SAND",
            "size": "XL",
            "sku": "UT-5000-SAD-XL"
        },
        {
            "id": 211,
            "color": "SAND",
            "size": "2XL",
            "sku": "UT-5000-SAD-2XL"
        },
        {
            "id": 212,
            "color": "SAND",
            "size": "3XL",
            "sku": "UT-5000-SAD-3XL"
        },
        {
            "id": 154,
            "color": "WHITE",
            "size": "S",
            "sku": "UT-5000-WHE-S"
        },
        {
            "id": 155,
            "color": "WHITE",
            "size": "M",
            "sku": "UT-5000-WHE-M"
        },
        {
            "id": 156,
            "color": "WHITE",
            "size": "L",
            "sku": "UT-5000-WHE-L"
        },
        {
            "id": 157,
            "color": "WHITE",
            "size": "XL",
            "sku": "UT-5000-WHE-XL"
        },
        {
            "id": 158,
            "color": "WHITE",
            "size": "2XL",
            "sku": "UT-5000-WHE-2XL"
        },
        {
            "id": 159,
            "color": "WHITE",
            "size": "3XL",
            "sku": "UT-5000-WHE-3XL"
        },
        {
            "id": 2760,
            "color": "WHITE",
            "size": "4XL",
            "sku": "UT-5000-WHE-4XL"
        },
        {
            "id": 3190,
            "color": "WHITE",
            "size": "5XL",
            "sku": "UT-5000-WHE-5XL"
        },
        {
            "id": 160,
            "color": "SPORT GREY",
            "size": "S",
            "sku": "UT-5000-SPY-S"
        },
        {
            "id": 161,
            "color": "SPORT GREY",
            "size": "M",
            "sku": "UT-5000-SPY-M"
        },
        {
            "id": 162,
            "color": "SPORT GREY",
            "size": "L",
            "sku": "UT-5000-SPY-L"
        },
        {
            "id": 163,
            "color": "SPORT GREY",
            "size": "XL",
            "sku": "UT-5000-SPY-XL"
        },
        {
            "id": 164,
            "color": "SPORT GREY",
            "size": "2XL",
            "sku": "UT-5000-SPY-2XL"
        },
        {
            "id": 165,
            "color": "SPORT GREY",
            "size": "3XL",
            "sku": "UT-5000-SPY-3XL"
        },
        {
            "id": 166,
            "color": "DARK HEATHER",
            "size": "S",
            "sku": "UT-5000-DAR-S"
        },
        {
            "id": 167,
            "color": "DARK HEATHER",
            "size": "M",
            "sku": "UT-5000-DAR-M"
        },
        {
            "id": 169,
            "color": "DARK HEATHER",
            "size": "XL",
            "sku": "UT-5000-DAR-XL"
        },
        {
            "id": 170,
            "color": "DARK HEATHER",
            "size": "2XL",
            "sku": "UT-5000-DAR-2XL"
        },
        {
            "id": 171,
            "color": "DARK HEATHER",
            "size": "3XL",
            "sku": "UT-5000-DAR-3XL"
        },
        {
            "id": 2984,
            "color": "FOREST GREEN",
            "size": "S",
            "sku": "UT-5000-FON-S"
        },
        {
            "id": 2845,
            "color": "FOREST GREEN",
            "size": "M",
            "sku": "UT-5000-FON-M"
        },
        {
            "id": 2991,
            "color": "FOREST GREEN",
            "size": "L",
            "sku": "UT-5000-FON-L"
        },
        {
            "id": 2998,
            "color": "FOREST GREEN",
            "size": "XL",
            "sku": "UT-5000-FON-XL"
        },
        {
            "id": 2831,
            "color": "FOREST GREEN",
            "size": "2XL",
            "sku": "UT-5000-FON-2XL"
        },
        {
            "id": 2835,
            "color": "FOREST GREEN",
            "size": "3XL",
            "sku": "UT-5000-FON-3XL"
        },
        {
            "id": 112,
            "color": "LIGHT BLUE",
            "size": "S",
            "sku": "UT-5000-LIE-S"
        },
        {
            "id": 113,
            "color": "LIGHT BLUE",
            "size": "M",
            "sku": "UT-5000-LIE-M"
        },
        {
            "id": 114,
            "color": "LIGHT BLUE",
            "size": "L",
            "sku": "UT-5000-LIE-L"
        },
        {
            "id": 115,
            "color": "LIGHT BLUE",
            "size": "XL",
            "sku": "UT-5000-LIE-XL"
        },
        {
            "id": 116,
            "color": "LIGHT BLUE",
            "size": "2XL",
            "sku": "UT-5000-LIE-2XL"
        },
        {
            "id": 117,
            "color": "LIGHT BLUE",
            "size": "3XL",
            "sku": "UT-5000-LIE-3XL"
        },
        {
            "id": 195,
            "color": "LIGHT PINK",
            "size": "S",
            "sku": "UT-5000-LIK-S"
        },
        {
            "id": 196,
            "color": "LIGHT PINK",
            "size": "M",
            "sku": "UT-5000-LIK-M"
        },
        {
            "id": 197,
            "color": "LIGHT PINK",
            "size": "L",
            "sku": "UT-5000-LIK-L"
        },
        {
            "id": 198,
            "color": "LIGHT PINK",
            "size": "XL",
            "sku": "UT-5000-LIK-XL"
        },
        {
            "id": 199,
            "color": "LIGHT PINK",
            "size": "2XL",
            "sku": "UT-5000-LIK-2XL"
        },
        {
            "id": 200,
            "color": "LIGHT PINK",
            "size": "3XL",
            "sku": "UT-5000-LIK-3XL"
        },
        {
            "id": 3017,
            "color": "ROYAL",
            "size": "S",
            "sku": "UT-5000-ROL-S"
        },
        {
            "id": 3018,
            "color": "ROYAL",
            "size": "M",
            "sku": "UT-5000-ROL-M"
        },
        {
            "id": 3019,
            "color": "ROYAL",
            "size": "L",
            "sku": "UT-5000-ROL-L"
        },
        {
            "id": 3020,
            "color": "ROYAL",
            "size": "XL",
            "sku": "UT-5000-ROL-XL"
        },
        {
            "id": 3021,
            "color": "ROYAL",
            "size": "2XL",
            "sku": "UT-5000-ROL-2XL"
        },
        {
            "id": 3022,
            "color": "ROYAL",
            "size": "3XL",
            "sku": "UT-5000-ROL-3XL"
        },
        {
            "id": 142,
            "color": "RED",
            "size": "S",
            "sku": "UT-5000-RED-S"
        },
        {
            "id": 143,
            "color": "RED",
            "size": "M",
            "sku": "UT-5000-RED-M"
        },
        {
            "id": 144,
            "color": "RED",
            "size": "L",
            "sku": "UT-5000-RED-L"
        },
        {
            "id": 145,
            "color": "RED",
            "size": "XL",
            "sku": "UT-5000-RED-XL"
        },
        {
            "id": 146,
            "color": "RED",
            "size": "2XL",
            "sku": "UT-5000-RED-2XL"
        },
        {
            "id": 147,
            "color": "RED",
            "size": "3XL",
            "sku": "UT-5000-RED-3XL"
        },
        {
            "id": 136,
            "color": "PURPLE",
            "size": "S",
            "sku": "UT-5000-PUE-S"
        },
        {
            "id": 137,
            "color": "PURPLE",
            "size": "M",
            "sku": "UT-5000-PUE-M"
        },
        {
            "id": 138,
            "color": "PURPLE",
            "size": "L",
            "sku": "UT-5000-PUE-L"
        },
        {
            "id": 139,
            "color": "PURPLE",
            "size": "XL",
            "sku": "UT-5000-PUE-XL"
        },
        {
            "id": 140,
            "color": "PURPLE",
            "size": "2XL",
            "sku": "UT-5000-PUE-2XL"
        },
        {
            "id": 141,
            "color": "PURPLE",
            "size": "3XL",
            "sku": "UT-5000-PUE-3XL"
        },
        {
            "id": 124,
            "color": "NAVY",
            "size": "S",
            "sku": "UT-5000-NAY-S"
        },
        {
            "id": 125,
            "color": "NAVY",
            "size": "M",
            "sku": "UT-5000-NAY-M"
        },
        {
            "id": 126,
            "color": "NAVY",
            "size": "L",
            "sku": "UT-5000-NAY-L"
        },
        {
            "id": 127,
            "color": "NAVY",
            "size": "XL",
            "sku": "UT-5000-NAY-XL"
        },
        {
            "id": 128,
            "color": "NAVY",
            "size": "2XL",
            "sku": "UT-5000-NAY-2XL"
        },
        {
            "id": 129,
            "color": "NAVY",
            "size": "3XL",
            "sku": "UT-5000-NAY-3XL"
        },
        {
            "id": 201,
            "color": "MAROON",
            "size": "S",
            "sku": "UT-5000-MAN-S"
        },
        {
            "id": 202,
            "color": "MAROON",
            "size": "M",
            "sku": "UT-5000-MAN-M"
        },
        {
            "id": 203,
            "color": "MAROON",
            "size": "L",
            "sku": "UT-5000-MAN-L"
        },
        {
            "id": 204,
            "color": "MAROON",
            "size": "XL",
            "sku": "UT-5000-MAN-XL"
        },
        {
            "id": 205,
            "color": "MAROON",
            "size": "2XL",
            "sku": "UT-5000-MAN-2XL"
        },
        {
            "id": 206,
            "color": "MAROON",
            "size": "3XL",
            "sku": "UT-5000-MAN-3XL"
        },
        {
            "id": 3010,
            "color": "CHARCOAL",
            "size": "S",
            "sku": "UT-5000-CHL-S"
        },
        {
            "id": 3011,
            "color": "CHARCOAL",
            "size": "M",
            "sku": "UT-5000-CHL-M"
        },
        {
            "id": 3012,
            "color": "CHARCOAL",
            "size": "L",
            "sku": "UT-5000-CHL-L"
        },
        {
            "id": 3013,
            "color": "CHARCOAL",
            "size": "XL",
            "sku": "UT-5000-CHL-XL"
        },
        {
            "id": 3014,
            "color": "CHARCOAL",
            "size": "2XL",
            "sku": "UT-5000-CHL-2XL"
        },
        {
            "id": 3015,
            "color": "CHARCOAL",
            "size": "3XL",
            "sku": "UT-5000-CHL-3XL"
        },
        {
            "id": 172,
            "color": "ASH",
            "size": "S",
            "sku": "UT-5000-ASH-S"
        },
        {
            "id": 173,
            "color": "ASH",
            "size": "M",
            "sku": "UT-5000-ASH-M"
        },
        {
            "id": 174,
            "color": "ASH",
            "size": "L",
            "sku": "UT-5000-ASH-L"
        },
        {
            "id": 175,
            "color": "ASH",
            "size": "XL",
            "sku": "UT-5000-ASH-XL"
        },
        {
            "id": 176,
            "color": "ASH",
            "size": "2XL",
            "sku": "UT-5000-ASH-2XL"
        },
        {
            "id": 2730,
            "color": "ASH",
            "size": "3XL",
            "sku": "UT-5000-ASH-3XL"
        },
        {
            "id": 3225,
            "color": "DAISY",
            "size": "S",
            "sku": "UT-5000-DAY-S"
        },
        {
            "id": 3226,
            "color": "DAISY",
            "size": "M",
            "sku": "UT-5000-DAY-M"
        },
        {
            "id": 3227,
            "color": "DAISY",
            "size": "L",
            "sku": "UT-5000-DAY-L"
        },
        {
            "id": 3228,
            "color": "DAISY",
            "size": "XL",
            "sku": "UT-5000-DAY-XL"
        },
        {
            "id": 3229,
            "color": "DAISY",
            "size": "2XL",
            "sku": "UT-5000-DAY-2XL"
        },
        {
            "id": 3230,
            "color": "DAISY",
            "size": "3XL",
            "sku": "UT-5000-DAY-3XL"
        },
        {
            "id": 3217,
            "color": "TENNESSEE ORANGE",
            "size": "S",
            "sku": "UT-5000-TEE-S"
        },
        {
            "id": 3218,
            "color": "TENNESSEE ORANGE",
            "size": "M",
            "sku": "UT-5000-TEE-M"
        },
        {
            "id": 3219,
            "color": "TENNESSEE ORANGE",
            "size": "L",
            "sku": "UT-5000-TEE-L"
        },
        {
            "id": 3220,
            "color": "TENNESSEE ORANGE",
            "size": "XL",
            "sku": "UT-5000-TEE-XL"
        },
        {
            "id": 3221,
            "color": "TENNESSEE ORANGE",
            "size": "2XL",
            "sku": "UT-5000-TEE-2XL"
        },
        {
            "id": 3222,
            "color": "TENNESSEE ORANGE",
            "size": "3XL",
            "sku": "UT-5000-TEE-3XL"
        },
        {
            "id": 3394,
            "color": "ORANGE",
            "size": "S",
            "sku": "UT-5000-ORE-S"
        },
        {
            "id": 3408,
            "color": "ORANGE",
            "size": "M",
            "sku": "UT-5000-ORE-M"
        },
        {
            "id": 3418,
            "color": "ORANGE",
            "size": "L",
            "sku": "UT-5000-ORE-L"
        },
        {
            "id": 3428,
            "color": "ORANGE",
            "size": "XL",
            "sku": "UT-5000-ORE-XL"
        },
        {
            "id": 3442,
            "color": "ORANGE",
            "size": "2XL",
            "sku": "UT-5000-ORE-2XL"
        },
        {
            "id": 3472,
            "color": "ORANGE",
            "size": "3XL",
            "sku": "UT-5000-ORE-3XL"
        },
        {
            "id": 2988,
            "color": "NATURAL",
            "size": "S",
            "sku": "UT-5000-NAL-S"
        },
        {
            "id": 3402,
            "color": "NATURAL",
            "size": "M",
            "sku": "UT-5000-NAL-M"
        },
        {
            "id": 2995,
            "color": "NATURAL",
            "size": "L",
            "sku": "UT-5000-NAL-L"
        },
        {
            "id": 3002,
            "color": "NATURAL",
            "size": "XL",
            "sku": "UT-5000-NAL-XL"
        },
        {
            "id": 3436,
            "color": "NATURAL",
            "size": "2XL",
            "sku": "UT-5000-NAL-2XL"
        },
        {
            "id": 3466,
            "color": "NATURAL",
            "size": "3XL",
            "sku": "UT-5000-NAL-3XL"
        },
        {
            "id": 2983,
            "color": "DARK CHOCOLATE",
            "size": "S",
            "sku": "UT-5000-DAE-S"
        },
        {
            "id": 2844,
            "color": "DARK CHOCOLATE",
            "size": "M",
            "sku": "UT-5000-DAE-M"
        },
        {
            "id": 2990,
            "color": "DARK CHOCOLATE",
            "size": "L",
            "sku": "UT-5000-DAE-L"
        },
        {
            "id": 2997,
            "color": "DARK CHOCOLATE",
            "size": "XL",
            "sku": "UT-5000-DAE-XL"
        },
        {
            "id": 2830,
            "color": "DARK CHOCOLATE",
            "size": "2XL",
            "sku": "UT-5000-DAE-2XL"
        },
        {
            "id": 3462,
            "color": "DARK CHOCOLATE",
            "size": "3XL",
            "sku": "UT-5000-DAE-3XL"
        },
        {
            "id": 3233,
            "color": "HELICONIA",
            "size": "S",
            "sku": "UT-5000-HEA-S"
        },
        {
            "id": 3234,
            "color": "HELICONIA",
            "size": "M",
            "sku": "UT-5000-HEA-M"
        },
        {
            "id": 3235,
            "color": "HELICONIA",
            "size": "L",
            "sku": "UT-5000-HEA-L"
        },
        {
            "id": 3236,
            "color": "HELICONIA",
            "size": "XL",
            "sku": "UT-5000-HEA-XL"
        },
        {
            "id": 3237,
            "color": "HELICONIA",
            "size": "2XL",
            "sku": "UT-5000-HEA-2XL"
        },
        {
            "id": 3238,
            "color": "HELICONIA",
            "size": "3XL",
            "sku": "UT-5000-HEA-3XL"
        },
        {
            "id": 118,
            "color": "MILITARY GREEN",
            "size": "S",
            "sku": "UT-5000-MIN-S"
        },
        {
            "id": 119,
            "color": "MILITARY GREEN",
            "size": "M",
            "sku": "UT-5000-MIN-M"
        },
        {
            "id": 120,
            "color": "MILITARY GREEN",
            "size": "L",
            "sku": "UT-5000-MIN-L"
        },
        {
            "id": 121,
            "color": "MILITARY GREEN",
            "size": "XL",
            "sku": "UT-5000-MIN-XL"
        },
        {
            "id": 122,
            "color": "MILITARY GREEN",
            "size": "2XL",
            "sku": "UT-5000-MIN-2XL"
        },],
        "sku": "TG5000",
        "name": "Unisex-Gildan-UnisexT-Shirt",
    },
    {
        "variations": [{
            "id": 2947,
            "color": "PEPPER",
            "size": "S",
            "sku": "UT-1717-PER-S"
        },
        {
            "id": 2948,
            "color": "PEPPER",
            "size": "M",
            "sku": "UT-1717-PER-M"
        },
        {
            "id": 2949,
            "color": "PEPPER",
            "size": "L",
            "sku": "UT-1717-PER-L"
        },
        {
            "id": 2950,
            "color": "PEPPER",
            "size": "XL",
            "sku": "UT-1717-PER-XL"
        },
        {
            "id": 2951,
            "color": "PEPPER",
            "size": "2XL",
            "sku": "UT-1717-PER-2XL"
        },
        {
            "id": 2952,
            "color": "PEPPER",
            "size": "3XL",
            "sku": "UT-1717-PER-3XL"
        },
        {
            "id": 2902,
            "color": "BLACK",
            "size": "S",
            "sku": "UT-1717-BLK-S"
        },
        {
            "id": 2903,
            "color": "BLACK",
            "size": "M",
            "sku": "UT-1717-BLK-M"
        },
        {
            "id": 2904,
            "color": "BLACK",
            "size": "L",
            "sku": "UT-1717-BLK-L"
        },
        {
            "id": 2905,
            "color": "BLACK",
            "size": "XL",
            "sku": "UT-1717-BLK-XL"
        },
        {
            "id": 2906,
            "color": "BLACK",
            "size": "2XL",
            "sku": "UT-1717-BLK-2XL"
        },
        {
            "id": 2907,
            "color": "BLACK",
            "size": "3XL",
            "sku": "UT-1717-BLK-3XL"
        },
        {
            "id": 2955,
            "color": "IVORY",
            "size": "M",
            "sku": "UT-1717-IVY-M"
        },
        {
            "id": 2956,
            "color": "IVORY",
            "size": "L",
            "sku": "UT-1717-IVY-L"
        },
        {
            "id": 2957,
            "color": "IVORY",
            "size": "XL",
            "sku": "UT-1717-IVY-XL"
        },
        {
            "id": 2958,
            "color": "IVORY",
            "size": "2XL",
            "sku": "UT-1717-IVY-2XL"
        },
        {
            "id": 2959,
            "color": "IVORY",
            "size": "3XL",
            "sku": "UT-1717-IVY-3XL"
        },
        {
            "id": 2961,
            "color": "ORCHID",
            "size": "S",
            "sku": "UT-1717-ORD-S"
        },
        {
            "id": 2962,
            "color": "ORCHID",
            "size": "M",
            "sku": "UT-1717-ORD-M"
        },
        {
            "id": 2963,
            "color": "ORCHID",
            "size": "L",
            "sku": "UT-1717-ORD-L"
        },
        {
            "id": 2964,
            "color": "ORCHID",
            "size": "XL",
            "sku": "UT-1717-ORD-XL"
        },
        {
            "id": 2965,
            "color": "ORCHID",
            "size": "2XL",
            "sku": "UT-1717-ORD-2XL"
        },
        {
            "id": 2969,
            "color": "WHITE",
            "size": "M",
            "sku": "UT-1717-WHE-M"
        },
        {
            "id": 2970,
            "color": "WHITE",
            "size": "L",
            "sku": "UT-1717-WHE-L"
        },
        {
            "id": 2971,
            "color": "WHITE",
            "size": "XL",
            "sku": "UT-1717-WHE-XL"
        },
        {
            "id": 2972,
            "color": "WHITE",
            "size": "2XL",
            "sku": "UT-1717-WHE-2XL"
        },
        {
            "id": 3052,
            "color": "YAM",
            "size": "S",
            "sku": "UT-1717-YAM-S"
        },
        {
            "id": 3053,
            "color": "YAM",
            "size": "M",
            "sku": "UT-1717-YAM-M"
        },
        {
            "id": 3054,
            "color": "YAM",
            "size": "L",
            "sku": "UT-1717-YAM-L"
        },
        {
            "id": 3055,
            "color": "YAM",
            "size": "XL",
            "sku": "UT-1717-YAM-XL"
        },
        {
            "id": 3056,
            "color": "YAM",
            "size": "2XL",
            "sku": "UT-1717-YAM-2XL"
        },
        {
            "id": 3024,
            "color": "NAVY",
            "size": "S",
            "sku": "UT-1717-NAY-S"
        },
        {
            "id": 3025,
            "color": "NAVY",
            "size": "M",
            "sku": "UT-1717-NAY-M"
        },
        {
            "id": 3026,
            "color": "NAVY",
            "size": "L",
            "sku": "UT-1717-NAY-L"
        },
        {
            "id": 3027,
            "color": "NAVY",
            "size": "XL",
            "sku": "UT-1717-NAY-XL"
        },
        {
            "id": 3028,
            "color": "NAVY",
            "size": "2XL",
            "sku": "UT-1717-NAY-2XL"
        },
        {
            "id": 3003,
            "color": "MOSS",
            "size": "S",
            "sku": "UT-1717-MOS-S"
        },
        {
            "id": 3004,
            "color": "MOSS",
            "size": "M",
            "sku": "UT-1717-MOS-M"
        },
        {
            "id": 3005,
            "color": "MOSS",
            "size": "L",
            "sku": "UT-1717-MOS-L"
        },
        {
            "id": 3006,
            "color": "MOSS",
            "size": "XL",
            "sku": "UT-1717-MOS-XL"
        },
        {
            "id": 3007,
            "color": "MOSS",
            "size": "2XL",
            "sku": "UT-1717-MOS-2XL"
        },
        {
            "id": 3031,
            "color": "LIGHT GREEN",
            "size": "S",
            "sku": "UT-1717-LIN-S"
        },
        {
            "id": 3032,
            "color": "LIGHT GREEN",
            "size": "M",
            "sku": "UT-1717-LIN-M"
        },
        {
            "id": 3033,
            "color": "LIGHT GREEN",
            "size": "L",
            "sku": "UT-1717-LIN-L"
        },
        {
            "id": 3034,
            "color": "LIGHT GREEN",
            "size": "XL",
            "sku": "UT-1717-LIN-XL"
        },
        {
            "id": 3035,
            "color": "LIGHT GREEN",
            "size": "2XL",
            "sku": "UT-1717-LIN-2XL"
        },
        {
            "id": 3038,
            "color": "GRAPHITE",
            "size": "S",
            "sku": "UT-1717-GRE-S"
        },
        {
            "id": 3039,
            "color": "GRAPHITE",
            "size": "M",
            "sku": "UT-1717-GRE-M"
        },
        {
            "id": 3040,
            "color": "GRAPHITE",
            "size": "L",
            "sku": "UT-1717-GRE-L"
        },
        {
            "id": 3041,
            "color": "GRAPHITE",
            "size": "XL",
            "sku": "UT-1717-GRE-XL"
        },
        {
            "id": 3042,
            "color": "GRAPHITE",
            "size": "2XL",
            "sku": "UT-1717-GRE-2XL"
        },
        {
            "id": 3045,
            "color": "BLUE JEAN",
            "size": "S",
            "sku": "UT-1717-BLN-S"
        },
        {
            "id": 3046,
            "color": "BLUE JEAN",
            "size": "M",
            "sku": "UT-1717-BLN-M"
        },
        {
            "id": 3047,
            "color": "BLUE JEAN",
            "size": "L",
            "sku": "UT-1717-BLN-L"
        },
        {
            "id": 3048,
            "color": "BLUE JEAN",
            "size": "XL",
            "sku": "UT-1717-BLN-XL"
        },
        {
            "id": 3049,
            "color": "BLUE JEAN",
            "size": "2XL",
            "sku": "UT-1717-BLN-2XL"
        },
        {
            "id": 3170,
            "color": "BLOSSOM",
            "size": "S",
            "sku": "UT-1717-BLM-S"
        },
        {
            "id": 3171,
            "color": "BLOSSOM",
            "size": "M",
            "sku": "UT-1717-BLM-M"
        },
        {
            "id": 3172,
            "color": "BLOSSOM",
            "size": "L",
            "sku": "UT-1717-BLM-L"
        },
        {
            "id": 3173,
            "color": "BLOSSOM",
            "size": "XL",
            "sku": "UT-1717-BLM-XL"
        },
        {
            "id": 3174,
            "color": "BLOSSOM",
            "size": "2XL",
            "sku": "UT-1717-BLM-2XL"
        },
        {
            "id": 3177,
            "color": "MUSTARD",
            "size": "S",
            "sku": "UT-1717-MUD-S"
        },
        {
            "id": 3178,
            "color": "MUSTARD",
            "size": "M",
            "sku": "UT-1717-MUD-M"
        },
        {
            "id": 3179,
            "color": "MUSTARD",
            "size": "L",
            "sku": "UT-1717-MUD-L"
        },
        {
            "id": 3180,
            "color": "MUSTARD",
            "size": "XL",
            "sku": "UT-1717-MUD-XL"
        },
        {
            "id": 3181,
            "color": "MUSTARD",
            "size": "2XL",
            "sku": "UT-1717-MUD-2XL"
        },],
        "name": "Unisex-ComfortColor-UnisexT-Shirt",
        "sku": "TC1717",
    },
    {
        "variations": [{
            "id": 225,
            "color": "BLACK",
            "size": "S",
            "sku": "SW-18000-BLK-S"
        },
        {
            "id": 226,
            "color": "BLACK",
            "size": "M",
            "sku": "SW-18000-BLK-M"
        },
        {
            "id": 227,
            "color": "BLACK",
            "size": "L",
            "sku": "SW-18000-BLK-L"
        },
        {
            "id": 228,
            "color": "BLACK",
            "size": "XL",
            "sku": "SW-18000-BLK-XL"
        },
        {
            "id": 229,
            "color": "BLACK",
            "size": "2XL",
            "sku": "SW-18000-BLK-2XL"
        },
        {
            "id": 249,
            "color": "WHITE",
            "size": "S",
            "sku": "SW-18000-WHE-S"
        },
        {
            "id": 250,
            "color": "WHITE",
            "size": "M",
            "sku": "SW-18000-WHE-M"
        },
        {
            "id": 251,
            "color": "WHITE",
            "size": "L",
            "sku": "SW-18000-WHE-L"
        },
        {
            "id": 252,
            "color": "WHITE",
            "size": "XL",
            "sku": "SW-18000-WHE-XL"
        },
        {
            "id": 253,
            "color": "WHITE",
            "size": "2XL",
            "sku": "SW-18000-WHE-2XL"
        },
        {
            "id": 288,
            "color": "SAND",
            "size": "S",
            "sku": "SW-18000-SAD-S"
        },
        {
            "id": 289,
            "color": "SAND",
            "size": "M",
            "sku": "SW-18000-SAD-M"
        },
        {
            "id": 290,
            "color": "SAND",
            "size": "L",
            "sku": "SW-18000-SAD-L"
        },
        {
            "id": 291,
            "color": "SAND",
            "size": "XL",
            "sku": "SW-18000-SAD-XL"
        },
        {
            "id": 292,
            "color": "SAND",
            "size": "2XL",
            "sku": "SW-18000-SAD-2XL"
        },
        {
            "id": 219,
            "color": "SPORT GREY",
            "size": "S",
            "sku": "SW-18000-SPY-S"
        },
        {
            "id": 220,
            "color": "SPORT GREY",
            "size": "M",
            "sku": "SW-18000-SPY-M"
        },
        {
            "id": 221,
            "color": "SPORT GREY",
            "size": "L",
            "sku": "SW-18000-SPY-L"
        },
        {
            "id": 222,
            "color": "SPORT GREY",
            "size": "XL",
            "sku": "SW-18000-SPY-XL"
        },
        {
            "id": 223,
            "color": "SPORT GREY",
            "size": "2XL",
            "sku": "SW-18000-SPY-2XL"
        },
        {
            "id": 3059,
            "color": "ORANGE",
            "size": "S",
            "sku": "SW-18000-ORE-S"
        },
        {
            "id": 3060,
            "color": "ORANGE",
            "size": "M",
            "sku": "SW-18000-ORE-M"
        },
        {
            "id": 3061,
            "color": "ORANGE",
            "size": "L",
            "sku": "SW-18000-ORE-L"
        },
        {
            "id": 3062,
            "color": "ORANGE",
            "size": "XL",
            "sku": "SW-18000-ORE-XL"
        },
        {
            "id": 3063,
            "color": "ORANGE",
            "size": "2XL",
            "sku": "SW-18000-ORE-2XL"
        },
        {
            "id": 284,
            "color": "MILITARY GREEN",
            "size": "S",
            "sku": "SW-18000-MIN-S"
        },
        {
            "id": 285,
            "color": "MILITARY GREEN",
            "size": "M",
            "sku": "SW-18000-MIN-M"
        },
        {
            "id": 2824,
            "color": "MILITARY GREEN",
            "size": "L",
            "sku": "SW-18000-MIN-L"
        },
        {
            "id": 286,
            "color": "MILITARY GREEN",
            "size": "XL",
            "sku": "SW-18000-MIN-XL"
        },
        {
            "id": 2825,
            "color": "MILITARY GREEN",
            "size": "2XL",
            "sku": "SW-18000-MIN-2XL"
        },
        {
            "id": 3066,
            "color": "FOREST GREEN",
            "size": "S",
            "sku": "SW-18000-FON-S"
        },
        {
            "id": 3067,
            "color": "FOREST GREEN",
            "size": "M",
            "sku": "SW-18000-FON-M"
        },
        {
            "id": 3068,
            "color": "FOREST GREEN",
            "size": "L",
            "sku": "SW-18000-FON-L"
        },
        {
            "id": 3069,
            "color": "FOREST GREEN",
            "size": "XL",
            "sku": "SW-18000-FON-XL"
        },
        {
            "id": 3070,
            "color": "FOREST GREEN",
            "size": "2XL",
            "sku": "SW-18000-FON-2XL"
        },
        {
            "id": 231,
            "color": "DARK HEATHER",
            "size": "S",
            "sku": "SW-18000-DAR-S"
        },
        {
            "id": 232,
            "color": "DARK HEATHER",
            "size": "M",
            "sku": "SW-18000-DAR-M"
        },
        {
            "id": 233,
            "color": "DARK HEATHER",
            "size": "L",
            "sku": "SW-18000-DAR-L"
        },
        {
            "id": 234,
            "color": "DARK HEATHER",
            "size": "XL",
            "sku": "SW-18000-DAR-XL"
        },
        {
            "id": 235,
            "color": "DARK HEATHER",
            "size": "2XL",
            "sku": "SW-18000-DAR-2XL"
        },
        {
            "id": 237,
            "color": "NAVY",
            "size": "S",
            "sku": "SW-18000-NAY-S"
        },
        {
            "id": 238,
            "color": "NAVY",
            "size": "M",
            "sku": "SW-18000-NAY-M"
        },
        {
            "id": 239,
            "color": "NAVY",
            "size": "L",
            "sku": "SW-18000-NAY-L"
        },
        {
            "id": 240,
            "color": "NAVY",
            "size": "XL",
            "sku": "SW-18000-NAY-XL"
        },
        {
            "id": 241,
            "color": "NAVY",
            "size": "2XL",
            "sku": "SW-18000-NAY-2XL"
        },
        {
            "id": 278,
            "color": "MAROON",
            "size": "S",
            "sku": "SW-18000-MAN-S"
        },
        {
            "id": 279,
            "color": "MAROON",
            "size": "M",
            "sku": "SW-18000-MAN-M"
        },
        {
            "id": 280,
            "color": "MAROON",
            "size": "L",
            "sku": "SW-18000-MAN-L"
        },
        {
            "id": 281,
            "color": "MAROON",
            "size": "XL",
            "sku": "SW-18000-MAN-XL"
        },
        {
            "id": 282,
            "color": "MAROON",
            "size": "2XL",
            "sku": "SW-18000-MAN-2XL"
        },
        {
            "id": 3241,
            "color": "CHARCOAL",
            "size": "S",
            "sku": "SW-18000-CHL-S"
        },
        {
            "id": 3242,
            "color": "CHARCOAL",
            "size": "M",
            "sku": "SW-18000-CHL-M"
        },
        {
            "id": 3243,
            "color": "CHARCOAL",
            "size": "L",
            "sku": "SW-18000-CHL-L"
        },
        {
            "id": 3244,
            "color": "CHARCOAL",
            "size": "XL",
            "sku": "SW-18000-CHL-XL"
        },
        {
            "id": 3245,
            "color": "CHARCOAL",
            "size": "2XL",
            "sku": "SW-18000-CHL-2XL"
        },
        {
            "id": 3249,
            "color": "LIGHT BLUE",
            "size": "S",
            "sku": "SW-18000-LIE-S"
        },
        {
            "id": 3276,
            "color": "LIGHT BLUE",
            "size": "M",
            "sku": "SW-18000-LIE-M"
        },
        {
            "id": 3295,
            "color": "LIGHT BLUE",
            "size": "L",
            "sku": "SW-18000-LIE-L"
        },
        {
            "id": 3314,
            "color": "LIGHT BLUE",
            "size": "XL",
            "sku": "SW-18000-LIE-XL"
        },
        {
            "id": 3333,
            "color": "LIGHT BLUE",
            "size": "2XL",
            "sku": "SW-18000-LIE-2XL"
        },],
        "name": "Unisex-Gildan-Sweatshirt",
        "sku": "SG18000",
    },
    {
        "variations": [{
            "id": 306,
            "color": "Black",
            "size": "S",
            "sku": "HG-18500-BLK-S"
        },
        {
            "id": 307,
            "color": "Black",
            "size": "M",
            "sku": "HG-18500-BLK-M"
        },
        {
            "id": 308,
            "color": "Black",
            "size": "L",
            "sku": "HG-18500-BLK-L"
        },
        {
            "id": 309,
            "color": "Black",
            "size": "XL",
            "sku": "HG-18500-BLK-XL"
        },
        {
            "id": 310,
            "color": "Black",
            "size": "2XL",
            "sku": "HG-18500-BLK-2XL"
        },
        {
            "id": 348,
            "color": "White",
            "size": "S",
            "sku": "HG-18500-WHE-S"
        },
        {
            "id": 349,
            "color": "White",
            "size": "M",
            "sku": "HG-18500-WHE-M"
        },
        {
            "id": 350,
            "color": "White",
            "size": "L",
            "sku": "HG-18500-WHE-L"
        },
        {
            "id": 351,
            "color": "White",
            "size": "XL",
            "sku": "HG-18500-WHE-XL"
        },
        {
            "id": 352,
            "color": "White",
            "size": "2XL",
            "sku": "HG-18500-WHE-2XL"
        },
        {
            "id": 354,
            "color": "Sand",
            "size": "S",
            "sku": "HG-18500-SAD-S"
        },
        {
            "id": 355,
            "color": "Sand",
            "size": "M",
            "sku": "HG-18500-SAD-M"
        },
        {
            "id": 356,
            "color": "Sand",
            "size": "L",
            "sku": "HG-18500-SAD-L"
        },
        {
            "id": 357,
            "color": "Sand",
            "size": "XL",
            "sku": "HG-18500-SAD-XL"
        },
        {
            "id": 358,
            "color": "Sand",
            "size": "2XL",
            "sku": "HG-18500-SAD-2XL"
        },
        {
            "id": 312,
            "color": "DARK HEATHER",
            "size": "S",
            "sku": "HG-18500-DAR-S"
        },
        {
            "id": 313,
            "color": "DARK HEATHER",
            "size": "M",
            "sku": "HG-18500-DAR-M"
        },
        {
            "id": 314,
            "color": "DARK HEATHER",
            "size": "L",
            "sku": "HG-18500-DAR-L"
        },
        {
            "id": 315,
            "color": "DARK HEATHER",
            "size": "XL",
            "sku": "HG-18500-DAR-XL"
        },
        {
            "id": 316,
            "color": "DARK HEATHER",
            "size": "2XL",
            "sku": "HG-18500-DAR-2XL"
        },
        {
            "id": 300,
            "color": "SPORT GREY",
            "size": "S",
            "sku": "HG-18500-SPY-S"
        },
        {
            "id": 301,
            "color": "SPORT GREY",
            "size": "M",
            "sku": "HG-18500-SPY-M"
        },
        {
            "id": 302,
            "color": "SPORT GREY",
            "size": "L",
            "sku": "HG-18500-SPY-L"
        },
        {
            "id": 303,
            "color": "SPORT GREY",
            "size": "XL",
            "sku": "HG-18500-SPY-XL"
        },
        {
            "id": 304,
            "color": "SPORT GREY",
            "size": "2XL",
            "sku": "HG-18500-SPY-2XL"
        },],
        "name": "Unisex-Gildan-Hoodie",
        "sku": "HG18500",
    },
    {
        "variations": [{
            "id": 3114,
            "color": "BLACK",
            "size": "S",
            "sku": "YT-5000B-BLK-S"
        },
        {
            "id": 3115,
            "color": "BLACK",
            "size": "M",
            "sku": "YT-5000B-BLK-M"
        },
        {
            "id": 3116,
            "color": "BLACK",
            "size": "L",
            "sku": "YT-5000B-BLK-L"
        },
        {
            "id": 3117,
            "color": "BLACK",
            "size": "XL",
            "sku": "YT-5000B-BLK-XL"
        },
        {
            "id": 3121,
            "color": "WHITE",
            "size": "S",
            "sku": "YT-5000B-WHE-S"
        },
        {
            "id": 3122,
            "color": "WHITE",
            "size": "M",
            "sku": "YT-5000B-WHE-M"
        },
        {
            "id": 3123,
            "color": "WHITE",
            "size": "L",
            "sku": "YT-5000B-WHE-L"
        },
        {
            "id": 3124,
            "color": "WHITE",
            "size": "XL",
            "sku": "YT-5000B-WHE-XL"
        },
        {
            "id": 3128,
            "color": "SAND",
            "size": "S",
            "sku": "YT-5000B-SAD-S"
        },
        {
            "id": 3129,
            "color": "SAND",
            "size": "M",
            "sku": "YT-5000B-SAD-M"
        },
        {
            "id": 3130,
            "color": "SAND",
            "size": "L",
            "sku": "YT-5000B-SAD-L"
        },
        {
            "id": 3131,
            "color": "SAND",
            "size": "XL",
            "sku": "YT-5000B-SAD-XL"
        },
        {
            "id": 3135,
            "color": "RED",
            "size": "S",
            "sku": "YT-5000B-RED-S"
        },
        {
            "id": 3136,
            "color": "RED",
            "size": "M",
            "sku": "YT-5000B-RED-M"
        },
        {
            "id": 3137,
            "color": "RED",
            "size": "L",
            "sku": "YT-5000B-RED-L"
        },
        {
            "id": 3138,
            "color": "RED",
            "size": "XL",
            "sku": "YT-5000B-RED-XL"
        },
        {
            "id": 3142,
            "color": "SPORT GREY",
            "size": "S",
            "sku": "YT-5000B-SPY-S"
        },
        {
            "id": 3143,
            "color": "SPORT GREY",
            "size": "M",
            "sku": "YT-5000B-SPY-M"
        },
        {
            "id": 3144,
            "color": "SPORT GREY",
            "size": "L",
            "sku": "YT-5000B-SPY-L"
        },
        {
            "id": 3145,
            "color": "SPORT GREY",
            "size": "XL",
            "sku": "YT-5000B-SPY-XL"
        },
        {
            "id": 3149,
            "color": "DARK HEATHER",
            "size": "S",
            "sku": "YT-5000B-DAR-S"
        },
        {
            "id": 3150,
            "color": "DARK HEATHER",
            "size": "M",
            "sku": "YT-5000B-DAR-M"
        },
        {
            "id": 3151,
            "color": "DARK HEATHER",
            "size": "L",
            "sku": "YT-5000B-DAR-L"
        },
        {
            "id": 3152,
            "color": "DARK HEATHER",
            "size": "XL",
            "sku": "YT-5000B-DAR-XL"
        },],
        "name": "Youth-Gildan-YouthT-Shirt",
        "sku": "5000B",
    },
    {
        "variations": [{
            "id": 3072,
            "color": "BLACK",
            "size": "S",
            "sku": "YT-3001Y-BLK-S"
        },
        {
            "id": 3073,
            "color": "BLACK",
            "size": "M",
            "sku": "YT-3001Y-BLK-M"
        },
        {
            "id": 3074,
            "color": "BLACK",
            "size": "L",
            "sku": "YT-3001Y-BLK-L"
        },
        {
            "id": 3075,
            "color": "BLACK",
            "size": "XL",
            "sku": "YT-3001Y-BLK-XL"
        },
        {
            "id": 3079,
            "color": "WHITE",
            "size": "S",
            "sku": "YT-3001Y-WHE-S"
        },
        {
            "id": 3080,
            "color": "WHITE",
            "size": "M",
            "sku": "YT-3001Y-WHE-M"
        },
        {
            "id": 3081,
            "color": "WHITE",
            "size": "L",
            "sku": "YT-3001Y-WHE-L"
        },
        {
            "id": 3082,
            "color": "WHITE",
            "size": "XL",
            "sku": "YT-3001Y-WHE-XL"
        },
        {
            "id": 3086,
            "color": "NATURAL",
            "size": "S",
            "sku": "YT-3001Y-NAL-S"
        },
        {
            "id": 3087,
            "color": "NATURAL",
            "size": "M",
            "sku": "YT-3001Y-NAL-M"
        },
        {
            "id": 3088,
            "color": "NATURAL",
            "size": "L",
            "sku": "YT-3001Y-NAL-L"
        },
        {
            "id": 3089,
            "color": "NATURAL",
            "size": "XL",
            "sku": "YT-3001Y-NAL-XL"
        },
        {
            "id": 3093,
            "color": "TRUE ROYAL",
            "size": "S",
            "sku": "YT-3001Y-TRL-S"
        },
        {
            "id": 3094,
            "color": "TRUE ROYAL",
            "size": "M",
            "sku": "YT-3001Y-TRL-M"
        },
        {
            "id": 3095,
            "color": "TRUE ROYAL",
            "size": "L",
            "sku": "YT-3001Y-TRL-L"
        },
        {
            "id": 3096,
            "color": "TRUE ROYAL",
            "size": "XL",
            "sku": "YT-3001Y-TRL-XL"
        },
        {
            "id": 3100,
            "color": "DARK GREY",
            "size": "S",
            "sku": "YT-3001Y-DAY-S"
        },
        {
            "id": 3101,
            "color": "DARK GREY",
            "size": "M",
            "sku": "YT-3001Y-DAY-M"
        },
        {
            "id": 3102,
            "color": "DARK GREY",
            "size": "L",
            "sku": "YT-3001Y-DAY-L"
        },
        {
            "id": 3103,
            "color": "DARK GREY",
            "size": "XL",
            "sku": "YT-3001Y-DAY-XL"
        },
        {
            "id": 3107,
            "color": "PINK",
            "size": "S",
            "sku": "YT-3001Y-PIK-S"
        },
        {
            "id": 3108,
            "color": "PINK",
            "size": "M",
            "sku": "YT-3001Y-PIK-M"
        },
        {
            "id": 3109,
            "color": "PINK",
            "size": "L",
            "sku": "YT-3001Y-PIK-L"
        },
        {
            "id": 3110,
            "color": "PINK",
            "size": "XL",
            "sku": "YT-3001Y-PIK-XL"
        },],
        "name": "Youth-BellaCanvas-YouthT-Shirt",
        "sku": "3001Y",
    },
    {
        "variations": [{
            "id": 2865,
            "color": "BLACK",
            "size": "M",
            "sku": "UT-3001-BLK-M"
        },
        {
            "id": 2909,
            "color": "BLACK",
            "size": "L",
            "sku": "UT-3001-BLK-L"
        },
        {
            "id": 2928,
            "color": "BLACK",
            "size": "XL",
            "sku": "UT-3001-BLK-XL"
        },
        {
            "id": 2871,
            "color": "White",
            "size": "M",
            "sku": "UT-3001-WHE-M"
        },
        {
            "id": 2915,
            "color": "White",
            "size": "L",
            "sku": "UT-3001-WHE-L"
        },
        {
            "id": 2934,
            "color": "White",
            "size": "XL",
            "sku": "UT-3001-WHE-XL"
        },
        {
            "id": 2976,
            "color": "NATURAL",
            "size": "M",
            "sku": "UT-3001-NAL-M"
        },
        {
            "id": 2977,
            "color": "NATURAL",
            "size": "L",
            "sku": "UT-3001-NAL-L"
        },
        {
            "id": 2978,
            "color": "NATURAL",
            "size": "XL",
            "sku": "UT-3001-NAL-XL"
        },],
        "name": "Unisex-BellaCanvas-UnisexT-Shirt",
        "sku": "3001",
    },
    {
        "variations": [{
            "id": 3156,
            "color": "BLACK",
            "size": "S",
            "sku": "WT-5000L-BLK-S"
        },
        {
            "id": 3157,
            "color": "BLACK",
            "size": "M",
            "sku": "WT-5000L-BLK-M"
        },
        {
            "id": 3159,
            "color": "BLACK",
            "size": "XL",
            "sku": "WT-5000L-BLK-XL"
        },
        {
            "id": 3160,
            "color": "BLACK",
            "size": "2XL",
            "sku": "WT-5000L-BLK-2XL"
        },
        {
            "id": 3161,
            "color": "BLACK",
            "size": "3XL",
            "sku": "WT-5000L-BLK-3XL"
        },
        {
            "id": 3163,
            "color": "WHITE",
            "size": "S",
            "sku": "WT-5000L-WHE-S"
        },
        {
            "id": 3164,
            "color": "WHITE",
            "size": "M",
            "sku": "WT-5000L-WHE-M"
        },
        {
            "id": 3165,
            "color": "WHITE",
            "size": "L",
            "sku": "WT-5000L-WHE-L"
        },
        {
            "id": 3166,
            "color": "WHITE",
            "size": "XL",
            "sku": "WT-5000L-WHE-XL"
        },
        {
            "id": 3167,
            "color": "WHITE",
            "size": "2XL",
            "sku": "WT-5000L-WHE-2XL"
        },
        {
            "id": 3168,
            "color": "WHITE",
            "size": "3XL",
            "sku": "WT-5000L-WHE-3XL"
        }],
        "name": "Women-Gildan-WomenT-Shirt",
        "sku": "5000L",
    }

]
export default beeful

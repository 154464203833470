
import Grid from "@mui/material/Grid";

// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data

// Dashboard components
import MDTypography from "components/MDTypography";
import ReportsBarMultilineChart from "examples/Charts/BarCharts/ReportsBarMultilineChart";
import { getDashboard } from "features/slices";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getStore } from "features/slices";
function Dashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.user.dashboard);
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const filterStore = useSelector((state) => state.user.filterStore);
  const order_status = useSelector((state) => state.user.order_status);
  const currentPage = useSelector((state) => state.user.currentPage);
  const labels = dashboard?.sku ? Object.keys(dashboard?.sku.total) : [];
  const data = dashboard?.sku ? Object.values(dashboard?.sku.total) : [];
  const chartData = {
    labels: labels,
    datasets: {
      label: "SKU",
      data: data
    }
  };

  const labels2 = dashboard?.sku ? Object.keys(dashboard?.statistics) : [];
  const data2 = dashboard?.sku ? Object.values(dashboard?.statistics) : [];

  // Cấu trúc dữ liệu cho biểu đồ
  const chartData2 = {
    labels: labels2,
    datasets: {
      label: "Design",
      data: data2
    }
  };


  const data4 = dashboard?.storeRevenue ? Object.values(dashboard?.storeRevenue) : [];
  const labels3 = dashboard?.storeStatistics ? Object.keys(dashboard?.storeStatistics) : [];
  const data3 = dashboard?.storeStatistics ? Object.values(dashboard?.storeStatistics) : [];
  const chartData3 = {
    labels: labels3,
    datasets: [
      {
        label: "Order",
        data: data3
      }, {
        label: "Revenue",
        data: data4
      }
    ],
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="store"
                title="Orders"
                count={dashboard?.order}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="money"
                title="Revenue"
                count={`$${dashboard?.price}`}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="done"
                title="Basecost"
                count={`$${dashboard?.basecost}`}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Success/Cancel"
                count={`${dashboard?.order}/${dashboard?.cancel}`}
                percentage={{
                  color: "error",
                  amount: `Cancel rate ${(dashboard?.cancel / dashboard?.order * 100).toFixed(2)}%`,
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3} mb={7}>
            {Object.keys(dashboard?.storeStatistics).map((items) => {
              let chartDataMulti = dashboard?.storeStatistics[items]
              const labelsItems = chartDataMulti ? Object.keys(chartDataMulti) : [];
              let dataItems = {}
              let dataItems2 = {}
              for (let i of Object.keys(chartDataMulti)) {
                dataItems = { ...dataItems, [i]: chartDataMulti[i].order }
                dataItems2 = { ...dataItems2, [i]: chartDataMulti[i].totalPrice }
              }
              const chartDataItems = {
                labels: labelsItems,
                datasets: [
                  {
                    label: "Order",
                    data: dataItems
                  }, {
                    label: "Revenue",
                    data: dataItems2
                  }
                ],
              };
              return (
                <Grid item xs={4} md={4} lg={4} key={items} textAlign={"center"}>
                  <p style={{ marginBottom: 25 }}>{items}</p>
                  <MDBox >
                    <ReportsBarMultilineChart
                      color="dark"
                      title="Statistic"
                      chart={chartDataItems} />
                  </MDBox>
                </Grid>
              )
            }

            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Another SKUs"
                  description={<span style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                    {dashboard?.sku.other.length > 0 ? dashboard?.sku.other.map((item, index) => (
                      <span className="caption-text" style={{ marginLeft: 5, fontWeight: "400" }} key={index}>
                        <span className="caption-text" style={{ fontWeight: "bold", color: "green" }}>👉 </span>
                        {item}
                      </span>
                    )) : null}
                  </span>}
                  date={`${moment(startDate).startOf("day").format("DD/MM/YYYY")} - ${moment(endDate).endOf("day").format("DD/MM/YYYY")}`}
                  chart={chartData}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="success"
                  title="Total Design"
                  // description={<span style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                  //   {dashboard?.sku.other.length > 0 ? dashboard?.sku.other.map((item, index) => (
                  //     <span className="caption-text" style={{ marginLeft: 5, fontWeight: "400" }} key={index}>
                  //       <span className="caption-text" style={{ fontWeight: "bold", color: "green" }}>👉 </span>
                  //       {item}
                  //     </span>
                  //   )) : null}
                  // </span>}
                  date={`${moment(startDate).startOf("day").format("DD/MM/YYYY")} - ${moment(endDate).endOf("day").format("DD/MM/YYYY")}`}
                  chart={chartData2}
                />
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

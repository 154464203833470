/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the EGEAD Fulfilment POD are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// EGEAD Fulfilment POD layouts
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";
import Products from "layouts/products";
import Tables from "layouts/order";
import Services from "layouts/services";
import Design from "layouts/design";
import Store from "layouts/store";

// @mui icons
import Icon from "@mui/material/Icon";
import Library from "layouts/listDesign";
import ErrorOrder from "layouts/error";
import History from "layouts/history";
import Feed from "layouts/feed";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/orders",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Services",
    key: "services",
    icon: <Icon fontSize="small">support</Icon>,
    route: "/services",
    component: <Services />,
  },
  {
    type: "collapse",
    name: "Error Order",
    key: "error",
    icon: <Icon fontSize="small">error</Icon>,
    route: "/error",
    component: <ErrorOrder />,
  },
  {
    type: "collapse",
    name: "Designs",
    key: "design",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/design",
    component: <Design />,
  },
  {
    type: "collapse",
    name: "Library",
    key: "library",
    icon: <Icon fontSize="small">movie</Icon>,
    route: "/library",
    component: <Library />,
  },
  {
    type: "collapse",
    name: "History",
    key: "history",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/history",
    component: <History />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    type: "collapse",
    name: "Store",
    key: "store",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/store",
    component: <Store />,
  },
  {
    type: "collapse",
    name: "File Feed",
    key: "feed",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/feed",
    component: <Feed />,
  },
  {
    type: "collapse",
    name: "Log Out",
    key: "log-out",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/log-out",
    component: <SignIn status={true} />,
  }
]
export default routes

/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
// Data
import { useEffect } from "react";

import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { getDesign } from "features/slices";
function Library() {
  const dispatch = useDispatch();
  const design = useSelector((state) => state.user.design || []);
  const search = useSelector((state) => state.user.search || []);

  useEffect(() => {
    dispatch(
      getDesign({ search })
    );
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {[0, 1, 2, 3, 4, 5].map((items, indexs) => (
              <div key={indexs} style={{ maxWidth: "33%" }} >
                {design.map((item, index) => (
                  index % 6 == items ? <div key={index} style={{ border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden', margin: 5, padding: 10 }}>
                    <img src={item[1]} alt={item[0]} loading="lazy"
                      // style={{ width: "100%", objectFit: 'cover', backgroundColor: "#C2DC9F", padding: 5, borderRadius: "0.5rem" }}
                      // className="transparent-background"
                      style={{ width: "100%", objectFit: 'cover', backgroundImage: "url(https://egead.nyc3.digitaloceanspaces.com/17229353145409tavwf.jpg)", padding: 5, borderRadius: "0.5rem" }}

                    />
                    <div style={{ padding: '16px' }}>
                      <p style={{ margin: 0, fontSize: '14px' }}>
                        {item[0]}
                      </p>
                    </div>
                  </div> : null
                ))}
              </div>
            ))}
          </div>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Library;

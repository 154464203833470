/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
// Data
import MDButton from "components/MDButton";
import { useState } from "react";

import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { getFeed, getTag } from "features/slices";
import { getFeedPOD } from "features/slices";
function generateRanges(total, step) {
  let ranges = [];
  for (let i = 1; i <= total; i += step) {
    let start = i;
    let end = Math.min(i + step - 1, total);
    ranges.push([start, end]);
  }
  return ranges;
}
function divideAndRoundUp(numerator, denominator) {
  const result = Math.ceil(numerator / denominator);
  return result;
}
function Feed() {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState('');
  const [domainPOD, setDomainPOD] = useState('');
  const [listTag, setListTag] = useState([]);
  const [listTagPOD, setListTagPOD] = useState([]);
  const [tag, setTag] = useState("");
  const [tagPOD, setTagPOD] = useState("");
  const [all, setAll] = useState(false);
  const handleExport = async () => {
    try {
      let combinedData = []
      const allData = []
      if (all) {
        for (let i = 1; i < 100; i++) {
          const res = await dispatch(getFeed({ domain: domain, tags: tag, start: i[0], end: i[1], all, page: i }))
          if (res.payload.data.length == 0) {
            break
          }
          allData.push(...res.payload.data)
        }
        combinedData = [[
          "id",
          "title",
          "link",
          "image link",
          "price",
          "categories",
          "description",
          "mpn",
          "gtin",
          "brand",
        ], ...allData]
      } else {
        const res = await dispatch(getFeed({ domain: domain, tags: tag }))
        combinedData = [[
          "id",
          "title",
          "link",
          "image link",
          "price",
          "categories",
          "description",
          "mpn",
          "gtin",
          "brand",
        ], ...res.payload.data]
      }
      const ws = XLSX.utils.aoa_to_sheet(combinedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${domain}-${tag}.csv`);
    } catch (error) {
      console.log(error)
    }

  }

  const handleChange = (e) => {
    setTag(e.target.value)
  }

  const handleGetTag = async () => {
    try {
      setTag("")
      setListTag([])
      const res = await dispatch(getTag({ domain: domain, tags: tag }))
      setTag(res.payload.data?.[0]?.value || "")
      setListTag(res.payload.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetTagPOD = async () => {
    try {
      setTagPOD("")
      setListTagPOD([])
      const res = await dispatch(getTag({ domain: domainPOD, tags: tagPOD }))
      setTagPOD(res.payload.data?.[0]?.value || "")
      setListTagPOD(res.payload.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleExportPOD = async () => {
    let allData = []
    try {
      let page = listTagPOD.filter(items => items.value == tagPOD)[0]
      const ranges = generateRanges(divideAndRoundUp(1000000, 100), 5);
      for (let i of ranges) {
        const res = await dispatch(getFeedPOD({ domain: domainPOD, tags: tagPOD, start: i[0], end: i[1] }))
        if (res.payload.data.length == 0) {
          break
        }
        allData.push(...res.payload.data)
      }
      const combinedData = [[
        "id",
        "title",
        "link",
        "image link",
        "price",
        "categories",
        "description",
      ], ...allData]
      const ws = XLSX.utils.aoa_to_sheet(combinedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${domainPOD}-${tagPOD}.csv`);
    } catch (error) {
      console.log(error)
    }

  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Card>
              <MDBox pt={3} pb={3} style={{ alignSelf: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
                <Typography sx={{ fontWeight: "bold", margin: 2 }}>Get Feed DROP</Typography>
                <TextField
                  sx={{ margin: 0.5 }}
                  autoFocus
                  margin="dense"
                  id="link"
                  label="Store Name"
                  type="text"
                  fullWidth
                  value={domain}
                  onChange={event => setDomain(event.target.value)}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={all}
                      onChange={e => setAll(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Get All"
                />
                {!all ? <MDButton variant="contained" onClick={handleGetTag} color="primary" component="span" sx={{ margin: 1 }}>
                  Get Tag
                </MDButton> : null}
                {listTag.length ? <FormControl sx={{ margin: 1 }}>
                  <InputLabel>Tag</InputLabel>
                  <Select
                    value={tag}
                    onChange={handleChange}
                    label="Tag"
                    sx={{ background: "white", height: 40 }}
                  >
                    {listTag && listTag.map(({ value, name }) => (
                      <MenuItem key={value} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> : null}
                {tag || all ? <MDButton variant="contained" onClick={handleExport} color="primary" component="span" sx={{ margin: 1 }}>
                  Get Feed
                </MDButton> : null}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <MDBox pt={3} pb={3} style={{ alignSelf: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
                <Typography sx={{ fontWeight: "bold", margin: 2 }}>Get Feed POD</Typography>
                <TextField
                  sx={{ margin: 0.5 }}
                  autoFocus
                  margin="dense"
                  id="link"
                  label="Store Name"
                  type="text"
                  fullWidth
                  value={domainPOD}
                  onChange={event => setDomainPOD(event.target.value)}
                />
                <MDButton variant="contained" onClick={handleGetTagPOD} color="primary" component="span" sx={{ margin: 1 }}>
                  Get Tag
                </MDButton>
                {listTagPOD.length ? <FormControl sx={{ margin: 1 }}>
                  <InputLabel>Tag</InputLabel>
                  <Select
                    value={tagPOD}
                    onChange={(e) => setTagPOD(e.target.value)}
                    label="Tag"
                    sx={{ background: "white", height: 40 }}
                  >
                    {listTagPOD && listTagPOD.map(({ value, name }) => (
                      <MenuItem key={value} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> : null}
                {tagPOD ? <MDButton variant="contained" onClick={handleExportPOD} color="primary" component="span" sx={{ margin: 1 }}>
                  Get Feed
                </MDButton> : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Feed;

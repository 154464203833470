import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from "../api";
import { NotificationManager } from "react-notifications";
import { POST2 } from "api/index2";
const userSlice = createSlice({
  name: "user",
  initialState: { status: "idle", startDate: new Date(), record: [], search: "", endDate: new Date(), currentPage: 1, order_status: "", utc: false, user: "", filterStore: "All Store", store: [], dashboard: { price: 0, basecost: 0, order: 0, cancel: 0, sku: { other: [], total: {} }, statistics: {}, storeRevenue: {}, storeStatistics: {} }, data: { data: [], currentPage: 1, totalPage: 1 }, permission: [], isLoggedIn: false, time: null, hash: null },
  reducers: {
    // IMMER
    addTodo: (state, action) => {
      state.push(action.payload);
    }, // action creators
    toggleTodoStatus: (state, action) => {
      const currentTodo = state.find((todo) => todo.id === action.payload);
      if (currentTodo) {
        currentTodo.completed = !currentTodo.completed;
      }
    }, setLoading: (state, action) => {
      state.isLoading = action.payload;
    }, stopLoading: (state) => {
      state.isLoading = false;
    }, loginSuccess: (state) => {
      state.isLoggedIn = true;
    }, logout: (state) => {
      state.isLoading = false;;
      state.time = null;
      state.hash = null;
    },
    setTimeHash: (state, action) => {
      state.time = action.payload.time;
      state.hash = action.payload.hash;
      state.email = action.payload.email;
    },
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
    setUtc(state, action) {
      state.utc = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setOrderStatus(state, action) {
      state.order_status = action.payload;
    },
    setStore(state, action) {
      state.filterStore = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginGG.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Login thành công ", "Alert", 1000);
          state.isLoggedIn = true
          state.user = res.user
          state.permission = res?.permission
        } else {
          NotificationManager.error("Login Fail ", "Error", 1000);

        }
      })
      .addCase(loginGG.pending, (state, action) => {
        NotificationManager.success("Đang Login !", "Alert", 1000);
        state.isLoggedIn = false
      })
      .addCase(loginGG.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Login Fail !', 'Error', 1000);
        state.isLoggedIn = false
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const res = action.payload;
        state.data = res;
        if (res.totalPage < state.currentPage) {
          state.currentPage = 1
        }
        state.isLoading = false
      })
      .addCase(getOrders.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
        state.data = { data: [], currentPage: 1, totalPage: 1 }
      })

      .addCase(getOrdersSearch.fulfilled, (state, action) => {
        const res = action.payload;
        state.data = res;
        if (res.totalPage < state.currentPage) {
          state.currentPage = 1
        }
        state.isLoading = false
      })
      .addCase(getOrdersSearch.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getOrdersSearch.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
        state.data = { data: [], currentPage: 1, totalPage: 1 }
      })

      .addCase(exportOrder.fulfilled, (state, action) => {
      })
      .addCase(exportOrder.pending, (state, action) => {
      })
      .addCase(exportOrder.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })

      .addCase(getDesign.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          state.design = res.data;
        }
      })
      .addCase(getDesign.pending, (state, action) => {
      })
      .addCase(getDesign.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(getRecord.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          state.record = res.data;
        }
      })
      .addCase(getRecord.pending, (state, action) => {
      })
      .addCase(getRecord.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        const res = action.payload;
        state.dashboard = res;
      })
      .addCase(getDashboard.pending, (state, action) => {

      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
        state.dashboard = { price: 0, basecost: 0, order: 0, cancel: 0, sku: { other: [], total: {} }, statistics: {}, storeRevenue: {}, storeStatistics: {} }
      })
      .addCase(getFeed.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(getFeed.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getFeed.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(getFeedPOD.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(getFeedPOD.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getFeedPOD.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(getNewOrder.fulfilled, (state, action) => {
        state.isLoading = false
        NotificationManager.success(action.payload.data, 'Notification', 1000);
      })
      .addCase(getNewOrder.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getNewOrder.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getTag.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(getTag.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getTag.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(editOrder.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error("Fail ", "Error", 1000);
        }
      })
      .addCase(editOrder.pending, (state, action) => {

      })
      .addCase(editOrder.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })
      .addCase(receiveDesign.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Received", "Alert", 1000);
        } else {
          NotificationManager.error("There has been a recipient!", "Error", 1000);
        }
      })
      .addCase(receiveDesign.pending, (state, action) => {

      })
      .addCase(receiveDesign.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })
      .addCase(filterImage.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.data.length > 0) {
          NotificationManager.success("Received", "Alert", 1000);
        } else {
          NotificationManager.error("There has been a recipient!", "Error", 1000);
        }
      })
      .addCase(filterImage.pending, (state, action) => {

      })
      .addCase(filterImage.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })

      .addCase(changeimage.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.data.length > 0) {
          NotificationManager.success("Received", "Alert", 1000);
        } else {
          NotificationManager.error("There has been a recipient!", "Error", 1000);
        }
      })
      .addCase(changeimage.pending, (state, action) => {

      })
      .addCase(changeimage.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })
      .addCase(newLink.fulfilled, (state, action) => {
      })
      .addCase(newLink.pending, (state, action) => {
      })
      .addCase(newLink.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(deleteDesign.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error("Fail ", "Error", 1000);
        }
      })
      .addCase(deleteDesign.pending, (state, action) => {

      })
      .addCase(deleteDesign.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(uploadDesign.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error("Fail ", "Error", 1000);
        }
      })
      .addCase(uploadDesign.pending, (state, action) => {

      })
      .addCase(uploadDesign.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })
      .addCase(ffmango.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error(res.message, "Error", 1000);
        }
      })
      .addCase(ffmango.pending, (state, action) => {

      })
      .addCase(ffmango.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(ffbeeful.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error(res.message, "Error", 1000);
        }
      })
      .addCase(ffbeeful.pending, (state, action) => {

      })
      .addCase(ffbeeful.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);
      })
      .addCase(ffmerchfox.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error(res.message, "Error", 1000);
        }
      })
      .addCase(ffmerchfox.pending, (state, action) => {

      })
      .addCase(ffmerchfox.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })
      .addCase(ffprintway.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error(res.message, "Error", 1000);
        }
      })
      .addCase(ffprintway.pending, (state, action) => {

      })
      .addCase(ffprintway.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })

      .addCase(getStore.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          state.store = res.data
        } else {
          NotificationManager.error("Fail ", "Error", 1000);
        }
      })
      .addCase(getStore2.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getStore2.rejected, (state, action) => {
        state.isLoading = false
        NotificationManager.error('Server not responding!', 'Error', 1000);

      })
      .addCase(getStore2.fulfilled, (state, action) => {
        state.isLoading = false
        const res = action.payload;
        if (res.status == 0) {
          state.store = res.data
        } else {
          NotificationManager.error("Fail ", "Error", 1000);
        }
      })


      .addCase(addStore.fulfilled, (state, action) => {
        const res = action.payload;
        if (res.status == 0) {
          NotificationManager.success("Success", "Alert", 1000);
        } else {
          NotificationManager.error(res.message, "Error", 1000);
        }
      })
  },
});


export const loginGG = createAsyncThunk("user/logingg", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/logingg", { hash: newTodo });
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const editOrder = createAsyncThunk("user/editOrder", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/editorders", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});


export const ffmango = createAsyncThunk("user/mango", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/mango", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const ffbeeful = createAsyncThunk("user/beeful", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/beeful", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const ffprintway = createAsyncThunk("user/printway", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/printway", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});
export const ffmerchfox = createAsyncThunk("user/merchfox", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/merchfox", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const receiveDesign = createAsyncThunk("user/receiveDesign", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/receivedesign", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const newLink = createAsyncThunk("user/newLink", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/convert", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const deleteDesign = createAsyncThunk("user/deleteDesign", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/deletedesign", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const filterImage = createAsyncThunk("user/filterImage", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST2("/filterimage", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const changeimage = createAsyncThunk("user/changeimage", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST2("/changeimage", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const uploadDesign = createAsyncThunk("user/uploadDesign", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/uploaddesign", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const getOrders = createAsyncThunk("user/getOrders", async (newTodo) => {
  const res = await GET("/getorders", newTodo);
  return res.data;
});

export const getOrdersSearch = createAsyncThunk("user/getOrdersSearch", async (newTodo) => {
  const res = await GET("/getordersearch", newTodo);
  return res.data;
});

export const getFeed = createAsyncThunk("user/getFeed", async (newTodo) => {
  const res = await GET("/getfeed", newTodo);
  return res.data;
});

export const getFeedPOD = createAsyncThunk("user/getFeedPOD", async (newTodo) => {
  const res = await GET("/getfeedpod", newTodo);
  return res.data;
});

export const getTag = createAsyncThunk("user/getTag", async (newTodo) => {
  const res = await GET("/gettag", newTodo);
  return res.data;
});

export const getRecord = createAsyncThunk("user/getRecord", async (newTodo) => {
  const res = await GET("/getrecord", newTodo);
  return res.data;
});

export const exportOrder = createAsyncThunk("user/exportOrder", async (newTodo) => {
  const res = await GET("/exportorder", newTodo);
  return res.data;
});


export const getDesign = createAsyncThunk("user/getDesign", async (newTodo) => {
  const res = await GET("/getdesign", newTodo);
  return res.data;
});

export const getDashboard = createAsyncThunk("user/getDashboard", async (newTodo) => {
  const res = await GET("/dashboard", newTodo);
  return res.data;
});
export const getStore = createAsyncThunk("user/getStore", async (newTodo, { dispatch }) => {
  try {
    const res = await GET("/getstore", newTodo);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getStore2 = createAsyncThunk("user/getStore2", async (newTodo, { dispatch }) => {
  try {
    const res = await GET("/getstore2", newTodo);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getNewOrder = createAsyncThunk("user/inputorders", async (newTodo, { dispatch }) => {
  try {
    const res = await GET("/inputorders", newTodo);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const addStore = createAsyncThunk("user/addStore", async (newTodo, { dispatch }) => {
  try {
    dispatch(userSlice.actions.setLoading(true)); // Đặt isLoading thành true trước khi gửi yêu cầu
    const res = await POST("/addstore", newTodo);
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false sau khi nhận dữ liệu
    return res.data;
  } catch (error) {
    dispatch(userSlice.actions.setLoading(false)); // Đặt isLoading thành false nếu có lỗi
    throw error;
  }
});

export const updateStartDate = (date) => (dispatch) => {
  dispatch(setStartDate(date));
};

export const updateEndDate = (date) => (dispatch) => {
  dispatch(setEndDate(date));
};

export const updateSearch = (date) => (dispatch) => {
  dispatch(setSearch(date));
};

export const updateStore = (date) => (dispatch) => {
  dispatch(setStore(date));
};

export const updateLoading = (date) => (dispatch) => {
  dispatch(setLoading(date));
};

export const updateUTC = (date) => (dispatch) => {
  dispatch(setUtc(date));
};

export const updateCurrentPage = (date) => (dispatch) => {
  dispatch(setCurrentPage(date));
};
export const updateOrderStatus = (date) => (dispatch) => {
  dispatch(setOrderStatus(date));
};


export const { loginSuccess, logout, setTimeHash, setStartDate, setEndDate, setSearch, setLoading, setStore, setUtc, setCurrentPage, setOrderStatus } = userSlice.actions;
export default userSlice;
